import currencyFormatter from 'currency-formatter';
import moment from 'moment';


export const INGRESSO_NÃO_EMITIDO = "Ingresso Não Emitido";
export const INGRESSO_EMITIDO = "Ingresso Emitido";
export const INGRESSO_INVÁLIDO = "Ingresso Inválido";
export const INGRESSO_CANCELADO = "Ingresso Cancelado";
export const INGRESSO_UTILIZADO = "Ingresso Utilizado";

export default {

  formatDecimal(value) {
    return currencyFormatter.format(value, {
      symbol: '',
      decimal: ',',
      thousand: '.',
      precision: 2,
      format: '%v', // %v is the value and %s is the symbol
    });
  },

  formatDecimalInCents(valueInCents) {
    return this.formatDecimal(valueInCents / 100.00);
  },

  parseStringTwoDecimalsCurrencyToValueInCents(currencyFormattedStrValue) {
    let cleanStr = currencyFormattedStrValue.replace(/[,\\.]/g, '');
    let valueInCents = Number(cleanStr);
    return valueInCents;
  },

  formatDateDDMMYYYYHHMM(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).format("DD/MM/YYYY HH:mm");
    return formatted;
  },

  formatDateDDMMYYYY(datetimeToFormat) {
    if (!datetimeToFormat) {
      return datetimeToFormat;
    }
    const formatted = moment(datetimeToFormat).format("DD/MM/YYYY");
    return formatted;
  },

  formatPassportStatus(status) {
    if ("CREATED" === status) {
        return INGRESSO_NÃO_EMITIDO;
    } else if ("ISSUED" === status) {
        return INGRESSO_EMITIDO;
    } else if ("FAILED" === status) {
        return INGRESSO_INVÁLIDO;
    } else if ("CANCELED" === status) {
        return INGRESSO_CANCELADO;
    } else if ("BURNED" === status) {
        return INGRESSO_UTILIZADO;
    } else {
        return status;
    }
  },

  resolvePassportStatusColor(status) {
    if ("CREATED" === status) {
        return "#ef5350";
    } else if ("ISSUED" === status) {
        return "#64dd17";
    } else if ("FAILED" === status) {
        return "#ef5350";
    } else if ("CANCELED" === status) {
        return "#ef5350";
    } else if ("BURNED" === status) {
        return "#ef5350";
    } else {
        return status;
    }
  },

  formatUuid(uuid) {
    let result = uuid;
    if (uuid.length > 8) {
      let formattedUuid = uuid.substr(0, 4);
      formattedUuid += uuid.substr(uuid.length - 4);
      result = formattedUuid;
    }
    return result.toUpperCase();
  }

}