import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BlockUi from 'react-block-ui';
import TextField from '@material-ui/core/TextField';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import BottomNavigation from '@material-ui/core/BottomNavigation';

import 'react-block-ui/style.css';

// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: '11pt',
  },
  fieldValue: {
    fontSize: '11pt',
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  chartBottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: '80px',
    textAlign: 'center',
    verticalAlign: 'bottom',
  },

  eventLogo: {
    borderRadius: '10px',
  },

  productControls: {
    verticalAlign: 'middle', // FIXME: it doesn't work...
  },
  qtyIconBtn: {
    height: 60,
    width: 60,
  },

});

class PassportSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      userFirebaseUid: '',
      eventDTO: props.location.state.eventDTO,
      passportDefs: [],
      myEventPassports: [],
      totalOrderAmountInCents: 0,

      bottomNavSelected: 0,
      blocking: false,
    };
  }

  componentWillMount() {
       this.blockUi(true);
       fire.auth().onAuthStateChanged(user => {
         this.blockUi(false);
         this.setState({
           ...this.state,
           isSignedIn: !!user,
           userFirebaseUid: user.uid,
         });

         // console.log("user", user);
         // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
        this.refresh();
    }

    refresh = () => {
        // window.location.reload();
        this.fetchEventPassportDefs();
    }

    fetchEventPassportDefs = () => {
      this.blockUi(true);
      backend.getEventPassportDefinitions(this.state.eventDTO.id)
          .then(data => {
              this.blockUi(false);
              // console.log('eventVisitorOrders', data);
              this.setState({
                  passportDefs: data.map((passportDefDTO) => {
                    passportDefDTO.cartQuantity = 0;
                    passportDefDTO.sellPassports = (
                      passportDefDTO.visible 
                      && passportDefDTO.active
                      && passportDefDTO.maxSoldPassports >= passportDefDTO.totalSoldPassports
                    ); // TODO check it
                    return passportDefDTO;
                  }),
              });
          }).catch((err) => {
              this.blockUi(false);
              console.error('Error fetching passport definitions.', err);
              throw err;
          });
    }

    addProductToCart = (passportDefDTO) => {
      let { passportDefs } = this.state;
      passportDefs = passportDefs.map((passportDefDTOItem) => {
        if (passportDefDTO.id === passportDefDTOItem.id) {
          passportDefDTO.cartQuantity++;
          return passportDefDTO;
        } else {
          return passportDefDTOItem;
        }
      });
      this.setState({
        ...this.state,
        passportDefs,
      });
      this.calculateTotalOrderAmout();
    }

    removeProductFromCart = (passportDefDTO) => {
      let { passportDefs } = this.state;
      passportDefs = passportDefs.map((passportDefDTOItem) => {
        if (passportDefDTO.id === passportDefDTOItem.id) {
          if (passportDefDTO.cartQuantity > 0) {
            passportDefDTO.cartQuantity--;
          }
          return passportDefDTO;
        } else {
          return passportDefDTOItem;
        }
      });
      this.setState({
        ...this.state,
        passportDefs,
      });
      this.calculateTotalOrderAmout();
    }

    calculateTotalOrderAmout = () => {
      let totalInCents = 0;
      this.state.passportDefs.map((passportDefDTOItem) => {
        if (passportDefDTOItem.cartQuantity > 0) {
          totalInCents += passportDefDTOItem.cartQuantity * passportDefDTOItem.priceInCents;
        }
        return passportDefDTOItem;
      });
      this.setState({
        ...this.state,
        totalOrderAmountInCents: totalInCents,
      });
    }

    confirm = () => {
      // console.log('state', this.state); // FIXME remove it
      this.goToOrderConfirmationPage();
    }

    renderPassportDefs = () => {
        const { classes } = this.props;
        let passportDefList = [];
        if (this.state.passportDefs) {
            this.state.passportDefs.map((passportDefDTO) => {
                if (!passportDefDTO.active || !passportDefDTO.visible) {
                  return;
                }
                passportDefList.push(
                  <Grid item xs={12} md={6} key={passportDefDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                      
                      <Typography color='secondary' gutterBottom>
                        { passportDefDTO.name }
                        {/* <br />
                        { passportDefDTO.name } */}
                      </Typography>
                      <div>
                          { passportDefDTO.description ? (
                          <div>
                              <label className={classes.fieldName}>Descrição: </label>
                              <span className={classes.fieldValue}>{passportDefDTO.description}</span>
                          </div>
                          ) : (
                            <span></span>
                          )}
                          <div>
                              <label className={classes.fieldName}>Preço: </label>
                              <span className={classes.fieldValue}>
                                { katanaUtil.formatDecimalInCents(passportDefDTO.priceInCents) }
                              </span>
                          </div>
                          {/* <div>
                              <label className={classes.fieldName}>Taxa: </label>
                              <span className={classes.fieldValue}>
                                { katanaUtil.formatDecimalInCents(passportDefDTO.transactionFeeInCents) }
                              </span>
                          </div> */}
                          <div>
                              <label className={classes.fieldName}>Venda disponível até: </label>
                              <span className={classes.fieldValue}>{ katanaUtil.formatDateDDMMYYYYHHMM(passportDefDTO.endSaleDate) }</span>
                          </div>
                          <div>
                              { passportDefDTO.sellPassports ? 
                                  <center>
                                  <div className={classes.productControls}>
                                    <IconButton aria-label="Remover" onClick={() => this.removeProductFromCart(passportDefDTO)}>
                                      <RemoveRoundedIcon color='primary' className={classes.qtyIconBtn} />
                                    </IconButton>
                                    <TextField
                                      value={passportDefDTO.cartQuantity}
                                      variant="outlined"
                                      margin="normal"
                                      id="qty"
                                      label="Qtde"
                                      name="qty"
                                      autoComplete="qty"
                                      style={{width: '50px'}}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                    <IconButton aria-label="Adicionar" onClick={() => this.addProductToCart(passportDefDTO)}>
                                      <AddRoundedIcon color='primary' className={classes.qtyIconBtn} />
                                    </IconButton>
                                  </div>
                                  </center>
                              :  
                                  <span></span>
                              }
                          </div>
                      </div>

                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return passportDefList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>

              <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="Voltar"
                      edge="start"
                      onClick={() => this.goToEventDetails()}
                      className={classes.menuButton}
                    >
                      <ArrowBack />
                    </IconButton>

                    <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                    {/* <Typography variant="h6" noWrap>
                      Bla
                    </Typography> */}
                  </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                {/* <Grid spacing={1} alignItems="center" justify="center" container >
                  <div style={{backgroundColor: '#00897b', borderRadius: '10px', width: '200dp', margin: '30px 40px 5px 40px'}}>
                      <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                  </div>
                </Grid> */}

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <center>
                      <div className={classes.box}>
                        <Typography color='secondary' gutterBottom>
                            Ingressos - {this.state.eventDTO.name}
                        </Typography>
                      </div>
                      </center>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderPassportDefs() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>


              <BottomNavigation
                className={classes.chartBottomNav}
                showLabels={false}
              >
                <div style={{marginTop: '5px'}}>
                  <b>Total:</b> 
                  { katanaUtil.formatDecimalInCents(this.state.totalOrderAmountInCents) } 
                  <br />
                  <Button onClick={() => this.confirm()} 
                      color='primary' variant="contained" 
                      className={classes.actionButtom}
                  >
                  Comprar
                  </Button>
                </div>
              </BottomNavigation>

              </div>
            ) : (
                <div>
                  {!this.state.blocking ? (
                    <div>
                      <h2>Invalid credentials, please login.</h2>
                      {/* <h3>TODO forward to Home</h3> */}
                      <button onClick={this.goToHome}>Voltar</button>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
            )}

          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goToEventDetails = (e) => {
        this.props.history.push({
            pathname: '/event-details',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToOrderConfirmationPage = () => {
      this.props.history.push({
          pathname: '/passport-checkout',
          state: { 
            eventDTO: this.state.eventDTO,
            passportDefs: this.state.passportDefs,
            totalOrderAmountInCents: this.state.totalOrderAmountInCents,
          }
      });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(PassportSelect));