import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import QRCode from 'qrcode.react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  statusNormal: {
    color: "#ffffff",
    fontSize: '14pt',
    backgroundColor: '#ef5350',
    borderRadius: '4pt',
    padding: '5pt',
  },

  statusIssued: {
    color: "#ffffff",
    fontSize: '14pt',
    backgroundColor: '#64dd17',
    borderRadius: '4pt',
    padding: '5pt',
  },

});

class MyPassportDetail extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        passportDTO: props.location.state.passportDTO,

        bottomNavSelected: 0,
      };
    }

    componentWillMount() {
       fire.auth().onAuthStateChanged(user => {
           this.setState({
               ...this.state,
               isSignedIn: !!user,
               userFirebaseUid: user.uid,
           });

           // console.log("user", user);
           // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
      // console.log('passportDTO', this.state.passportDTO);
      if (!this.state.passportDTO.passportDefinitionName) {
        this.fetchPassport();
      }
    }

    fetchPassport = () => {
      let passportId = this.state.passportDTO.id;
      backend.getMyPassportById(passportId)
          .then(data => {
              // console.log('passportDTO', data);
              this.setState({
                  passportDTO: data,
              });
          }).catch((err) => {
              console.error('Error fetching passportDTO. id=' + passportId, err);
              throw err;
          });
    }

    refresh = () => {
        // window.location.reload();
        this.fetchPassport();
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    edge="start"
                    onClick={() => this.goBack()}
                    className={classes.menuButton}
                  >
                    <ArrowBack />
                  </IconButton>

                  <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                  {/* <Typography variant="h6" noWrap>
                    Bla
                  </Typography> */}
                </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <center>
                      <div className={classes.box}>
                        
                        <Typography color='secondary' gutterBottom>
                            { this.state.passportDTO.eventName } 
                            <br />
                            { this.state.passportDTO.passportDefinitionName } 
                            <br />
                            { this.state.passportDTO.passportDefPriceInCents ? katanaUtil.formatDecimalInCents(this.state.passportDTO.passportDefPriceInCents) : '' }
                        </Typography>

                        <div style={{margin: '15px'}}>
                            <span className={this.resolveStatusClass(classes, this.state.passportDTO.status)}>
                              { katanaUtil.formatPassportStatus(this.state.passportDTO.status) }
                            </span>
                        </div>

                      </div>

                      <div>
                          <div style={{margin: '20px'}}>
                            <QRCode value={ this.state.passportDTO.hashQrCode }
                                style={{display: 'flex', justifyContent: 'flex-center'}}
                            />
                            <div className={classes.formatedQrCode}>
                              { katanaUtil.formatUuid(this.state.passportDTO.hashQrCode) }
                            </div>
                          </div>
                          {/* <div>
                              <label className={classes.fieldName}>Preço: </label>
                              <span className={classes.fieldValue}>
                                { katanaUtil.formatDecimal(passportDTO.definition.value) }
                              </span>
                          </div> */}
                          <div>
                            <label className={classes.fieldName}>Nome do Visitante: </label>
                            <span className={classes.fieldValue}>{this.state.passportDTO.visitorName}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>CPF: </label>
                              <span className={classes.fieldValue}>{this.state.passportDTO.visitorDocumentNumber}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Data Nascimento: </label>
                              <span className={classes.fieldValue}>{this.state.passportDTO.birthDate}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Email: </label>
                              <span className={classes.fieldValue}>{this.state.passportDTO.visitorEmail}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Telefone: </label>
                              <span className={classes.fieldValue}>{this.state.passportDTO.visitorPhone}</span>
                          </div>
                      </div>

                      <div>
                        <Button onClick={this.refresh} 
                            color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Atualizar
                        </Button>
                      </div>
                      </center>
                    </Paper>
                  </Grid>

                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>


              </div>

            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}

        </div>
      </React.Fragment>
      )
    }

    resolveStatusClass(classes, status) {
      if ("ISSUED" === status) {
          return classes.statusIssued;
      } else {
          return classes.statusNormal;
      }
    }

    goBack = (e) => {
      this.props.history.push({
          pathname: '/my-passports',
          state: { 
            eventDTO: this.props.location.state.eventDTO 
          }
      });
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(MyPassportDetail));