import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// import Navbar from './components/layout/Navbar'
import Home from './components/Home'
import EventWallet from './components/EventWallet'
import EventStands from './components/EventStands'
import StandProducts from './components/StandProducts'
import VisitorDetailForm from './components/VisitorDetailForm'
import TopUpWithCreditCardForm from './components/TopUpWithCreditCardForm'
import OrderProductsWithCreditCardForm from './components/OrderProductsWithCreditCardForm'
import CreditTransferForm from './components/CreditTransferForm'
import EventVisitorOrders from './components/EventVisitorOrders'
import VisitorOrderDetail from './components/VisitorOrderDetail'
import EventDetails from './components/EventDetails'
import PassportSelect from './components/PassportSelect'
import PassportCheckout from './components/PassportCheckout'
import MyPassports from './components/MyPassports'
import MyPassportDetail from './components/MyPassportDetail'
import Bla from './components/temp/Bla'
import Bla2 from './components/temp/Bla2'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import './App.css';
import 'react-credit-cards/es/styles-compiled.css';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: teal[900]
    },
    primary: {
      main: teal[600]
    },
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Nunito"',
      'sans-serif'
    ].join(',')
  }
});

class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
              <Switch>
                <Route exact path='/'component={Home} />
                <Route path='/event-wallet' component={EventWallet} />
                <Route path='/event-stands' component={EventStands} />
                <Route path='/event-visitor-orders' component={EventVisitorOrders} />
                <Route path='/stand-products' component={StandProducts} />
                <Route path='/visitor-detail-form' component={VisitorDetailForm} />
                <Route path='/top-up-form' component={TopUpWithCreditCardForm} />
                <Route path='/order-products-form' component={OrderProductsWithCreditCardForm} />
                <Route path='/credit-transfer-form' component={CreditTransferForm} />
                <Route path='/visitor-order-detail' component={VisitorOrderDetail} />
                <Route path='/event-details' component={EventDetails} />
                <Route path='/passport-select' component={PassportSelect} />
                <Route path='/passport-checkout' component={PassportCheckout} />
                <Route path='/my-passports' component={MyPassports} />
                <Route path='/my-passport-detail' component={MyPassportDetail} />
                <Route path='/bla' component={Bla} />
                <Route path='/bla2' component={Bla2} />
                {/* <Route path='/project/:id' component={ProjectDetails} /> */}
              </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;