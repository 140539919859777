import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EventIcon from '@material-ui/icons/Event';
import StoreIcon from '@material-ui/icons/Store';

// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  bottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
});

class EventVisitorOrders extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,
        orders: [],

        bottomNavSelected: 2,
      };
    }

    componentWillMount() {  
       fire.auth().onAuthStateChanged(user => {
           this.setState({
               ...this.state,
               isSignedIn: !!user,
               userFirebaseUid: user.uid,
           });

           // console.log("user", user);
           // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
        this.fetchEventVisitorOrders();
    }

    fetchEventVisitorOrders = () => {
        let eventId = this.state.eventDTO.id;
        backend.getEventVisitorOrders(eventId)
            .then(data => {
                // console.log('eventUserOrders', data);
                this.setState({
                    orders: data,
                });
            }).catch((err) => {
                console.error('Error fetching event user orders.', err);
                throw err;
            });
    }

    refresh = () => {
        // window.location.reload();
        this.fetchEventVisitorOrders();
    }

    renderOrders = () => {
        const { classes } = this.props;
        let orderList = [];
        if (this.state.orders) {
            this.state.orders.map((transactionDTO) => {
                // if (!transactionDTO.active) {
                //   return;
                // }
                orderList.push(
                  <Grid item xs={12} md={6} key={transactionDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                      
                      <Typography color='secondary' gutterBottom>
                          { transactionDTO.name } 
                      </Typography>
                      <div>
                          <div>
                            <span className={classes.fieldValue}>{ this.formatOrderStatus(transactionDTO.orderStatus) }</span>
                          </div>
                          <hr />
                          <div>
                            <label className={classes.fieldName}>Código: </label>
                            <span className={classes.fieldValue}>{ transactionDTO.id }</span>
                          </div>
                          <div>
                            <label className={classes.fieldName}>Descrição: </label>
                            <span className={classes.fieldValue}>{transactionDTO.description}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Data/Hora: </label>
                              <span className={classes.fieldValue}>{ new Date(transactionDTO.timestamp).toLocaleString() }</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Loja: </label>
                              <span className={classes.fieldValue}>{ transactionDTO.standName }</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Valor total: </label>
                              <span className={classes.fieldValue}>{ katanaUtil.formatDecimalInCents(transactionDTO.valueInCents) }</span>
                          </div>
                          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Button onClick={() => this.goToOrderDetail(transactionDTO)} 
                                  color='primary' variant="contained" className={classes.actionButtom}
                              >
                              Ver Pedido
                              </Button>
                          </div>
                      </div>
                      
                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return orderList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    edge="start"
                    onClick={() => this.goToHome()}
                    className={classes.menuButton}
                  >
                    <ArrowBack />
                  </IconButton>

                  <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                  {/* <Typography variant="h6" noWrap>
                    Bla
                  </Typography> */}
                </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography color='secondary' gutterBottom>
                            {this.state.eventDTO.name} - Lojas
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderOrders() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>

              <BottomNavigation
                value={this.state.bottomNavSelected}
                onChange={(event, newValue) => {
                  this.setState({
                    ...this.state,
                    bottomNavSelected: newValue,
                  });
                }}
                className={classes.bottomNav}
                showLabels={true}
              >
                <BottomNavigationAction label="Sobre" icon={<EventIcon />} onClick={this.goToEventDetails} />
                <BottomNavigationAction label="Lojas" icon={<StoreIcon />} onClick={this.goToEventStands} />
                <BottomNavigationAction label="Pedidos" icon={<ShoppingCartIcon />} />
              </BottomNavigation>

              </div>

            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}


        </div>
      </React.Fragment>
      )
    }

    goToOrderDetail = (transactionDTO) => {
      this.props.history.push({
          pathname: '/visitor-order-detail',
          state: { 
            eventDTO: this.state.eventDTO,
            transactionDTO,
          }
      });
    }

    goToEventDetails = (e) => {
        this.props.history.push({
            pathname: '/event-details',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goToEventStands = (e) => {
        this.props.history.push({
            pathname: '/event-stands',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

    formatOrderStatus = (orderStatus) => {
      if (orderStatus === 'CREATED') {
        return 'Pedido criado, aguardando confirmação';
      }
      if (orderStatus === 'CONFIRMED') {
        return 'Pedido confirmado, em preparo =)';
      }
      if (orderStatus === 'DONE') {
        return 'Pedido pronto! Favor retirá-lo na loja';
      }

      return orderStatus;
    }

  }
  
  export default withRouter(withStyles(styles)(EventVisitorOrders));