import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

// const backgroundShape = require('../images/shape.svg');

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
      color: theme.palette.text.secondary,
  },
});

class VisitorDetailForm extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        userFirebaseNotificationToken: props.location.state.userFirebaseNotificationToken,
        visitorDetails: {
            name: '',
            email: '',
            documentNumber: '',
            phoneNumber: '',
            photoURL: '',
        },
        emailEditable: true,
        phoneNumberEditable: true,
      };
    }

    componentWillMount() {
        fire.auth().onAuthStateChanged(user => {
            this.setState({ 
                isSignedIn: !!user,
                userFirebaseUid: user.uid,
                visitorDetails: {
                    ...this.state.visitorDetails,
                    name: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    photoURL: user.photoURL,
                },
                emailEditable: (!!user.email),
                phoneNumberEditable: (!!user.phoneNumber),
            });
            localStorage.setItem('uid', user.uid);

            this.fetchVisitorDetails();
        });
    }

    fetchVisitorDetails = () => {
        backend.getOpenUser()
            .then(data => {
                this.setState({
                    visitorDetails: {
                        ...this.state.visitorDetails,
                        name: data.name ? data.name : this.state.visitorDetails.name,
                        email: this.state.visitorDetails.email ? this.state.visitorDetails.email : data.email,
                        phoneNumber: this.state.visitorDetails.phoneNumber ? this.state.visitorDetails.phoneNumber : data.phoneNumber,
                        documentNumber: data.documentNumber ? data.documentNumber : '',
                    },
                });
            }).catch((err) => {
                console.error('Error fetching visitor details.', err);
                throw err;
            });
    }

    handleVisitorDetailChange = (event) => {
       this.setState({
         ...this.state,
         visitorDetails: {
          ...this.state.visitorDetails,
          [event.target.id]: event.target.value
          }
        });
    }

    handleSubmit = (event) => {
       event.preventDefault();
       this.updateVisitorDetails();
    }

    updateVisitorDetails = () => {
      // console.log('updateVisitorDetails. state: ', this.state); // TODO remove it

      // let documentNumber = this.state.visitorDetails.documentNumber.replace(/[\\.\\-]/g, '');

      let eventVisitorDTO = {
          // eventId: null, // when it's updating visitor data, eventId = null
          email: this.state.visitorDetails.email,
          name: this.state.visitorDetails.name,
          documentNumber: this.state.visitorDetails.documentNumber,
          phoneNumber: this.state.visitorDetails.phoneNumber,
          registrationToken: this.state.userFirebaseNotificationToken, // notificationToken
          firebaseUID: this.state.userFirebaseUid,
      }
      backend.createOrUpdateEventVisitor(eventVisitorDTO)
          .then(ticketIssueResponseDTO => {
              this.goToHome();
          })
          .catch(err => {
              console.error('Error trying to create/update eventVisitor data', err);
              // TODO show message error to user

              this.logout();
          });
    }

    // copied from Home.js =/
    logout = () => {
        fire.auth().signOut();
        this.setState({ 
            isSignedIn: false,
        });
        localStorage.removeItem('uid');
        localStorage.removeItem('backendJwt');
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
                        
            {this.state.isSignedIn ? (
              <Grid container justify="center">
                    
                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <form onSubmit={this.handleSubmit}>

                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
                          { !this.state.visitorDetails.photoURL ? (
                             <span></span>
                          ) : (
                            <img src={ this.state.visitorDetails.photoURL } 
                              alt="profilePhoto" width="50px" 
                              style={{display: 'inline', paddingRight: '10px'}} 
                            />
                          )}
                          { this.state.visitorDetails.name }
                        </Typography>
                        <Typography color='primary' gutterBottom>
                          Por favor inserir seus dados. =)
                        </Typography>
                        <div>
                            <div>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="name"
                                  label="Nome"
                                  name="name"
                                  autoComplete="name"
                                  autoFocus
                                  value={this.state.visitorDetails.name}
                                  onChange={this.handleVisitorDetailChange}
                                />
                            </div>
                            <div>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="email"
                                  label="Email"
                                  name="email"
                                  autoComplete="email"
                                  value={this.state.visitorDetails.email}
                                  onChange={this.handleVisitorDetailChange}
                                  InputProps={{
                                    readOnly: this.state.emailEditable,
                                  }}
                                />
                            </div>
                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    id="phoneNumber"
                                    label="Celular"
                                    name="phoneNumber"
                                    autoComplete="phoneNumber"
                                    value={this.state.visitorDetails.phoneNumber}
                                    onChange={this.handleVisitorDetailChange}
                                    InputProps={{
                                      readOnly: this.state.phoneNumberEditable,
                                      type: 'tel',
                                    }}
                                  />
                            </div>
                            {/* <div>
                                <InputMask 
                                  mask="999.999.999-99"
                                  maskChar=" "
                                  value={this.state.visitorDetails.documentNumber}
                                  onChange={this.handleVisitorDetailChange}
                                >
                                  {() => <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id="documentNumber"
                                    label="CPF"
                                    name="documentNumber"
                                    autoComplete="documentNumber"
                                  /> }
                                </InputMask>
                            </div> */}
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        <Button onClick={this.goToHome} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Voltar
                        </Button>
                        <Button type="submit"
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Atualizar
                        </Button>

                        {/* 
                        <Button onClick={() => this.getNotificationToken()} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          Request Push Permission
                        </Button> 
                        
                        <Button onClick={this.test} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          TEST
                        </Button> 
                        */}

                      </div>
                    </Paper>

                    </form>
                  </Grid>

                </Grid>

               {/* for debug
                <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div> 
                */}

              </Grid>
            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}
        </div>
      </React.Fragment>
      )
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(VisitorDetailForm));