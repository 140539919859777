import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BlockUi from 'react-block-ui';

import PaymentMethodFormModal from '../components/PaymentMethodFormModal';


import 'react-block-ui/style.css';

const NO_CARD_SELECTED = 'Favor selecionar...';
const NEW_CARD = 'Novo cartão';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
      color: theme.palette.text.secondary,
  },
});

class OrderProductsWithCreditCardForm extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,
        standDTO: props.location.state.standDTO,
        visitorDetails: {
            name: '',
            email: '',
            documentNumber: '',
            phoneNumber: '',
            photoURL: '',
        },
        cards: [],
        products: props.location.state.products,
        totalOrderAmount: katanaUtil.formatDecimalInCents(
          props.location.state.totalOrderAmountInCents
        ),
        totalOrderAmountInCents: props.location.state.totalOrderAmountInCents,

        selectedCreditCard: null,
        selectedCreditCardLabel: NO_CARD_SELECTED,
        creditCardDetails: {
          cardholdername: '',
          cardnumber: '',
          cvv: '',
          expiremonth: 0,
          expireyear: 0,
          cardflag: null,
          saveCard: false,
          label: '',
  
          cpf: '',
          birthdate: null,
          email: '',
          phone: '',
        },
        cvvForSavedCard: '',

        blocking: false,
        modalOpen: false,
      };
    }

    componentWillMount() {
      fire.auth().onAuthStateChanged(user => {
          this.setState({
              ...this.state,
              isSignedIn: !!user,
              userFirebaseUid: user.uid,
              visitorDetails: {
                  ...this.state.visitorDetails,
                  photoURL: user.photoURL,
              },
          });
          localStorage.setItem('uid', user.uid);

          this.fetchVisitorDetails();
          this.fetchVisitorCards();
      });
    }

    fetchVisitorDetails = () => {
        backend.getOpenUser()
            .then(data => {
                this.setState({
                    ...this.state,
                    visitorDetails: {
                        ...this.state.visitorDetails,
                        name: data.name,
                        email: data.email,
                        documentNumber: data.documentNumber,
                        phoneNumber: data.phoneNumber,
                    },
                    creditCardDetails: {
                      ...this.state.creditCardDetails,
                      name: data.name,
                      cpf: data.documentNumber,
                    },
                });
            }).catch((err) => {
                console.error('Error fetching visitor details.', err);
                throw err;
            });
    }

    fetchVisitorCards = () => {
      backend.getVisitorCards()
          .then(data => {
              let defaultCard = {};
              if (data && data.length > 0) {
                const card = data.pop();
                defaultCard = {
                  uuid: card.cardHash,
                  label: card.cardLabel + ' '  + card.cardFlag,
                };
              }

              this.setState({
                  ...this.state,
                  cards: data,
                  selectedCreditCard: defaultCard.uuid,
                  selectedCreditCardLabel: defaultCard.label,
              });
          }).catch((err) => {
              console.error('Error fetching visitor cards.', err);
              throw err;
          });
    }

    handleChange = (event) => {
      // console.log('event.target.id = ' +  event.target.id); // TODO remove it
      this.setState({
        ...this.state,
        [event.target.id]: event.target.value,
      });
    }

    handleCurrencyField = (event) => {
      let amountInCentsStr = event.target.value.replace(/[,\\.]/g, '');
      let amountInCents = Number(amountInCentsStr);
      this.setState({
        ...this.state,
        totalOrderAmount: katanaUtil.formatDecimalInCents(amountInCents),
        totalOrderAmountInCents: amountInCents,
      });
    }

    handleSubmit = (event) => {
       event.preventDefault();
       this.placeOrder();
    }

    // TODO improve it... =(
    placeOrder = () => {
      this.blockUi(true);

      // console.log('state', this.state); // TODO remove it 

      let amountInCents = this.state.totalOrderAmountInCents;

      if (!this.state.selectedCreditCard || this.state.selectedCreditCard === NO_CARD_SELECTED) {
        window.alert("Por favor selecionar um método de pagamento");
        this.blockUi(false);
        return;
      }

      let paymentMethod = {};
      if (this.state.selectedCreditCard === NEW_CARD) {
        paymentMethod = {
          "cardholdername": this.state.creditCardDetails.cardholdername,
          "cardnumber": this.state.creditCardDetails.cardnumber,
          "cvv": this.state.creditCardDetails.cvv,
          "expiremonth": this.state.creditCardDetails.expiremonth,
          "expireyear": this.state.creditCardDetails.expireyear,
          "cardflag": this.state.creditCardDetails.cardflag,
          "saveCard": this.state.creditCardDetails.saveCard,
          "paymenttype": "CREDIT_CARD",
          "cpf": this.state.creditCardDetails.cpf,
          "birthdate": this.state.creditCardDetails.birthdate,
        };
      } else {
        if (!this.state.cvvForSavedCard) {
          window.alert("Por favor preencher o código de segurança do cartão selecionado.");
          this.blockUi(false);
          return;
        }
        paymentMethod = {
          "savedCardHash": this.state.selectedCreditCard,
          "cvv": this.state.cvvForSavedCard,
          "paymenttype": "CREDIT_CARD",
        };
      }

      let productOrderDTO = {
        "eventId": this.state.eventDTO.id,
        "standId": this.state.standDTO.id,
        "ticket": this.state.eventDTO.ticket,
        "paymentType": 'POSTPAID',
        "details": null, // TODO
        "totalAmount": amountInCents,
        "transactionDesc": "Pedido Online",
        "requestDatetime": new Date().getTime(),
        "convenienceFee": null,
        "device": {
          "appVersionCode": "1.0", // TODO
          "deviceId": null, // TODO
          "lat": "0.0", // TODO
          "lng": "0.0", // TODO
          "phone": this.state.visitorDetails.phoneNumber,
          "platform": "katana web",
        },
        "payment": paymentMethod,
        "visitor": {
          "email": this.state.visitorDetails.email,
          "phone": this.state.visitorDetails.phoneNumber,
        },
        "voucher": null,
        "transactionProducts": this.mountTransactionProductList(),
      };

      // console.log('productOrderDTO', productOrderDTO); // FIXME : remove it

      backend.createProductOrder(productOrderDTO)
          .then(response => {
            this.blockUi(false);
            // console.log('order response', response);


            // TODO improve it... =(
            window.alert('Pedido efetuado com sucesso! =)');

            // TODO go to success page!
            this.goToEventVisitorOrders();


          })
          .catch(err => {
            this.blockUi(false);
            console.error('Error trying to place a product order with credit card', err);

            // TODO improve it... =(
            window.alert('Erro ao efetuar pedido... Nenhuma cobrança foi realizada. Dados do cartão parecem não estar corretos, se o erro persistir, talvez seja necessário entrar em contato com banco emissor do cartão.');


            // TODO go to error page!
            this.goToEventVisitorOrders();

          });
    }

    mountTransactionProductList = () => {
      let transactionProducts = [];
      let totalInCents = 0;

      this.state.products.map((productDTO) => {
        if (productDTO.cartQuantity > 0) {
          transactionProducts.push(
            {
              "eventId": productDTO.eventId,
              "eventName": productDTO.eventName,
              "productId": productDTO.id,
              "productName": productDTO.name,
              "qtde": productDTO.cartQuantity,
              "standId": productDTO.standId,
              "standName": productDTO.standName,
              "totalValueInCents": productDTO.cartQuantity * productDTO.valueInCents,
              "unitPriceInCents": productDTO.valueInCents,
              "id": null,
              "transactionId": null,
            }
          );
          totalInCents += productDTO.cartQuantity * productDTO.valueInCents;
        }
        return productDTO;
      });

      if (totalInCents !== this.state.totalOrderAmountInCents) {
        throw new Error({msg: 'Valor total do pedido inválido.'});
      }

      return transactionProducts;
    }

    setOpenPaymentModal = (open) => {
      this.setState({
        ...this.state,
        modalOpen: open,
      });
      // console.log('modalOpen', this.state.modalOpen);
    }

    handleClickOpen = () => {
      this.setOpenPaymentModal(true);
    }

    handleClose = (selectedCard) => {
      // console.log('selectedCard', selectedCard); // debug
      // this.setOpenPaymentModal(false); didnt work properly...

      const { cardDetails } = selectedCard;
      
      if (cardDetails.uuid === NEW_CARD) {
        this.setState({
          ...this.state,
          selectedCreditCard: cardDetails.uuid,
          selectedCreditCardLabel: cardDetails.label,
          creditCardDetails: {
            ...this.state.creditCardDetails,
            "cardholdername": cardDetails.cardholdername,
            "cardnumber": cardDetails.cardnumber,
            "cvv": cardDetails.cvv,
            "expiremonth": cardDetails.expiremonth,
            "expireyear": cardDetails.expireyear,
            "cardflag": cardDetails.cardflag,
            "saveCard": cardDetails.saveCard,
          },
          modalOpen: false,
        });
      } else {
        this.setState({
          ...this.state,
          tmp: cardDetails, // bug... 
          selectedCreditCard: cardDetails.uuid,
          selectedCreditCardLabel: cardDetails.label,
          modalOpen: false,
        });
      }

    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>

            {this.state.isSignedIn ? (
              <Grid container justify="center">

                <Grid spacing={1} alignItems="center" justify="center" container >
                  <div style={{backgroundColor: '#00897b', borderRadius: '10px', width: '200dp', margin: '30px 40px 5px 40px'}}>
                      <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                  </div>
                </Grid>
                    
                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>

                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <form onSubmit={this.handleSubmit}>

                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
                          { !this.state.visitorDetails.photoURL ? (
                             <span></span>
                          ) : (
                            <img src={ this.state.visitorDetails.photoURL } 
                                alt="profilePhoto" width="50px" 
                                style={{display: 'inline', paddingRight: '10px'}} 
                            />
                          )}
                          { this.state.visitorDetails.name }
                        </Typography>
                        <div>
                            <div>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  label="Valor total do Pedido"
                                  id="totalOrderAmount"
                                  name="totalOrderAmount"
                                  autoComplete="totalOrderAmount"
                                  autoFocus
                                  value={this.state.totalOrderAmount}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>

                            <div>

                              <TextField
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  label="Método de Pagamento"
                                  id="paymentMethodTextField"
                                  name="paymentMethodTextField"
                                  value={this.state.selectedCreditCardLabel}
                                  onChange={this.handleChange}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={this.handleClickOpen}
                              />
                              <PaymentMethodFormModal 
                                  modalOpen={this.state.modalOpen} 
                                  onHandleClose={this.handleClose} />
                              {/* <h2>selectedCard: { this.state.selectedCreditCard }</h2> */}

                            </div>

                            <div>
                              { this.state.selectedCreditCard 
                                  && this.state.selectedCreditCard !== NEW_CARD ? (
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Código de segurança do cartão selecionado"
                                    id="cvvForSavedCard"
                                    name="cvvForSavedCard"
                                    value={this.state.cvvForSavedCard}
                                    onChange={this.handleChange}
                                    InputProps={{
                                      type: 'tel',
                                    }}
                                />
                              ) :(
                                <span></span>
                              )}
                            </div>

                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        <Button onClick={this.goToBackToEvent} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Voltar
                        </Button>
                        <Button type="submit"
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Confirmar
                        </Button>

                        {/* 
                        <Button onClick={() => this.getNotificationToken()} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          Request Push Permission
                        </Button> 
                        
                        <Button onClick={this.test} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          TEST
                        </Button> 
                        */}

                      </div>
                    </Paper>

                    </form>
                  </Grid>

                </Grid>

               {/* for debug */}
               {/* <div className="bla">
                  <button onClick={this.test}>TEST</button>
                  <br />
                  <a href="/bla">Bla</a>
               </div> */}

              </Grid>
            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}
          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goToBackToEvent = (e) => {
        this.props.history.push({
            pathname: '/event-stands', // TODO change to /event-orders
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToEventVisitorOrders = (e) => {
        this.props.history.push({
            pathname: '/event-visitor-orders',
            state: { 
              eventDTO: this.state.eventDTO 
            }
        });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(OrderProductsWithCreditCardForm));