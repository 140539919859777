import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QrReader from 'react-qr-reader';
import BlockUi from 'react-block-ui';

import 'react-block-ui/style.css';

const NO_QR_CODE_DETECTED = 'Favor realizar leitura do QRCode da Carteira Destino';
const INVALID_QR_CODE = 'QRCode da Carteira Destino inválido, tente novamente.';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
      color: theme.palette.text.secondary,
  },
});

class CreditTransferForm extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,
        balance: props.location.state.balance,
        transferAmount: '', // 0,00
        transferInCents: 0,
        balanceAfter: {
            valueCents: 0,
        },
        ticketDestQRCode: null,
        ticketDestQRCodeMsg: NO_QR_CODE_DETECTED,
        blocking: false,
      };
    }

    componentWillMount() {
      fire.auth().onAuthStateChanged(user => {
          this.setState({
              ...this.state,
              isSignedIn: !!user,
              userFirebaseUid: user.uid,
              visitorDetails: {
                  ...this.state.visitorDetails,
                  photoURL: user.photoURL,
              },
          });
          localStorage.setItem('uid', user.uid);
      });
    }

    handleCurrencyField = (event) => {
      let amountInCents = katanaUtil.parseStringTwoDecimalsCurrencyToValueInCents(event.target.value);
      this.setState({
        ...this.state,
        transferAmount: katanaUtil.formatDecimalInCents(amountInCents),
        transferAmountInCents: amountInCents,
        balanceAfter: {
          valueCents: this.state.balance.valueCents - amountInCents,
        },
      });
    }

    handleScan = data => {
      console.log('QRCode scan data:', data);
      if (data) {

        if (data.length < 64) {
          this.setState({
            ...this.state,
            ticketDestQRCode: null,
            ticketDestQRCodeMsg: INVALID_QR_CODE,
          });
          data = null;
          return;
        }

        let readedQRCode = data;
        if (data.length > 64) {

          // TODO
          // console.log('readedQRCode', readedQRCode);
          // let url = new URLSearchParams(readedQRCode);
          // if (url) {
          //   console.log('url', url);
          //   console.log('url.has(h)', url.has('h'));
          //   readedQRCode = url.get('h');
          //   console.log('readedQRCode', readedQRCode);
          // }
          
          readedQRCode = data.substring(data.length - 64);
          // console.log('readedQRCode sliced', readedQRCode);

        }

        this.setState({
          ...this.state,
          ticketDestQRCode: readedQRCode,
          ticketDestQRCodeMsg: null,
        });

      }
    }

    handleScanError = err => {
      console.error('QRCode scan error:', err);
    }

    handleSubmit = (event) => {
       event.preventDefault();
       this.transfer();
    }

    // TODO improve it... =(
    transfer = () => {
      this.blockUi(true);

      let amountInCents = this.state.transferAmountInCents;

      if (amountInCents > this.state.balance.valueCents) {
        window.alert('Saldo indisponível para transferência desejada...');
        this.blockUi(false);
        return;
      }

      if (!this.state.ticketDestQRCode) {
        window.alert('Carteira destino inválida...');
        this.blockUi(false);
        return;
      }

      let transferDTO = {
        // "detail": null,
        // "message": null,
        // "path": null,
        // "status": 0,
        // "title": null,
        // "type": null,
        "valueCents": amountInCents,
        "t": this.state.eventDTO.ticket,
        "d": this.state.ticketDestQRCode,
    };

      backend.transferCredits(transferDTO)
          .then(response => {
            this.blockUi(false);
            // console.log('transfer response', response);


            // TODO improve it... =(
            window.alert('Transferência efetuada com sucesso! =)');

            // TODO go to success page!
            this.goToBackToEvent();


          })
          .catch(err => {
            this.blockUi(false);
            console.error('Error trying to transfer wallet credits', err);

            // TODO improve it... =(
            window.alert('Erro ao tentar transferir créditos... Nenhuma transferência foi efetivada.');


            // TODO go to error page!
            this.goToBackToEvent();

              
          });
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>

            {this.state.isSignedIn ? (
              <Grid container justify="center">

                <Grid spacing={1} alignItems="center" justify="center" container >
                  <div style={{backgroundColor: '#00897b', borderRadius: '10px', width: '200dp', margin: '30px 40px 5px 40px'}}>
                      <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                  </div>
                </Grid>
                    
                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <form onSubmit={this.handleSubmit}>

                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
                          { this.state.eventDTO.name }
                        </Typography>
                        <div>
                            <div>
                                  <TextField
                                    margin="normal"
                                    fullWidth
                                    id="balance"
                                    label="Saldo Disponível"
                                    name="balance"
                                    autoComplete="balance"
                                    value={ katanaUtil.formatDecimalInCents(this.state.balance.valueCents) }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                            </div>
                            <div>
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="transferAmount"
                                    label="Valor da Transferência"
                                    name="transferAmount"
                                    autoComplete="transferAmount"
                                    autoFocus
                                    value={this.state.transferAmount}
                                    onChange={this.handleCurrencyField}
                                    InputProps={{
                                      type: 'tel',
                                    }}
                                  />
                            </div>
                            <div>
                                  <TextField
                                    margin="normal"
                                    fullWidth
                                    id="balanceAfter"
                                    label="Saldo Disponível após a Transferência"
                                    name="balanceAfter"
                                    autoComplete="balanceAfter"
                                    value={ katanaUtil.formatDecimalInCents(this.state.balanceAfter.valueCents) }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                            </div>
                            <div>
                                  { !this.state.ticketDestQRCode || this.state.ticketDestQRCodeMsg ? (
                                    <Typography color='secondary' gutterBottom style={{color: '#e53935', fontSize: '20pt'}}>
                                      { this.state.ticketDestQRCodeMsg }
                                    </Typography>
                                  ) : (
                                    <Typography color='secondary' gutterBottom style={{color: '#41bf28', fontSize: '20pt'}}>
                                      Carteira Destino Detectada com sucesso!
                                    </Typography>
                                  )}
                            </div>
                            <div>
                              <QrReader
                                delay={300}
                                onError={this.handleScanError}
                                onScan={this.handleScan}
                                style={{ width: '100%' }}
                              />
                            </div>
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        <Button onClick={this.goToBackToEvent} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Voltar
                        </Button>
                        <Button type="submit"
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Paper>

                    </form>
                  </Grid>

                </Grid>

               {/* for debug */}
               {/* <div className="bla">
                  <button onClick={this.test}>TEST</button>
                  <br />
                  <a href="/bla">Bla</a>
               </div> */}

              </Grid>
            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}
          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goToBackToEvent = (e) => {
        this.props.history.push({
            pathname: '/event-wallet',
            state: { 
              eventDTO: this.state.eventDTO 
            }
        });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(CreditTransferForm));