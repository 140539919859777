// import firebase from "firebase";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";

var firebaseConfig = {
apiKey: "AIzaSyBO_LhlhN2Jtog0aPL0DG2RRsFKF3f6CkQ",
authDomain: "payaqui-3c78e.firebaseapp.com",
databaseURL: "https://payaqui-3c78e.firebaseio.com",
projectId: "payaqui-3c78e",
storageBucket: "payaqui-3c78e.appspot.com",
messagingSenderId: "100011825547",
appId: "1:100011825547:web:b68476c85d1e2a5f"
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);

export default fire;