import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cards from 'react-credit-cards'; // https://github.com/amarofashion/react-credit-cards
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BlockUi from 'react-block-ui';

import 'react-block-ui/style.css';

const NEW_CARD = 'Novo cartão';

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.grey['100'],
        // overflow: 'hidden',
        // backgroundSize: 'cover',
        // backgroundPosition: '0 400px',
        // paddingBottom: 100
    },
});

class PaymentMethodFormModal extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        visitorDetails: {
            name: '',
            email: '',
            documentNumber: '',
            phoneNumber: '',
            photoURL: '',
        },
        cards: [],

        creditCardDetails: {
          uuid: NEW_CARD, // NEW_CARD, cardUuid (if selected)
          name: '',
          number: '',
          expiry: '',
          cvv: '', 
          valid: false,
          saveCard: false,
          label: '',
          focused: '',

          cpf: '',
        },

        blocking: false,
        modalOpen: props.modalOpen,
      };
    }

    componentDidMount() {
        this.fetchVisitorDetails();
        this.fetchVisitorCards();
    }

    fetchVisitorDetails = () => {
        backend.getOpenUser()
            .then(data => {
                this.setState({
                    ...this.state,
                    visitorDetails: {
                        ...this.state.visitorDetails,
                        name: data.name,
                        email: data.email,
                        documentNumber: data.documentNumber,
                        phoneNumber: data.phoneNumber,
                    },
                    creditCardDetails: {
                      ...this.state.creditCardDetails,
                      name: data.name,
                      cpf: data.documentNumber,
                    },
                });
            }).catch((err) => {
                console.error('Error fetching visitor details.', err);
                throw err;
            });
    }

    fetchVisitorCards = () => {
      this.blockUi(true);
      backend.getVisitorCards()
          .then(data => {
              this.blockUi(false);
              this.setState({
                  ...this.state,
                  cards: data,
              });
          }).catch((err) => {
              this.blockUi(false);
              console.error('Error fetching visitor cards.', err);
              throw err;
          });
    }

    handleChange = (event) => {
      // console.log('event',  event); // TODO remove it
      this.setState({
        ...this.state,
        [event.target.id]: event.target.value,
      });
    }

    handleCreditCardDetailChange = (event) => {
       // console.log('event',  event); // TODO remove it
       this.setState({
         ...this.state,
         creditCardDetails: {
            ...this.state.creditCardDetails,
            [event.target.id]: event.target.value
          }
        });
    }

    handleInputFocus = ({ target }) => {
      this.setState({
        ...this.state,
        creditCardDetails: {
          ...this.state.creditCardDetails,
          focused: target.id,
        }
      });
    };

    handleRadioGroupChange = (event, value) => {
      // console.log('event, value',  event, value); // TODO remove it
      this.setState({
        ...this.state,
        creditCardDetails: {
          ...this.state.creditCardDetails,
          uuid: value,
        }
      });
    }

    continue = () => {
        if (this.state.creditCardDetails.uuid !== NEW_CARD) {
            const card = this.state.cards.filter((c) => {
                return c.cardHash === this.state.creditCardDetails.uuid;
            })[0];
            const cardLabel = card.cardFlag + ' ' + card.cardLabel;
            let cardCheckingDTO = {
                uuid: this.state.creditCardDetails.uuid,
                label: cardLabel,
            };
            this.close(cardCheckingDTO);
            return;
        } else {
            this.validateAndSaveNewCard();
            return;
        }
    }

    validateAndSaveNewCard = () => { 
      this.blockUi(true);

      // console.log('state', this.state); // TODO remove it 

      let cardnumber = this.state.creditCardDetails.number.replace(/\s/g, '');

      if (this.state.creditCardDetails.expiry.includes('_')) {
        window.alert('Data de expiração do cartão inválida...');
        this.blockUi(false);
        return;
      }
      let expiryArr = this.state.creditCardDetails.expiry.split('/');
      let expiryMonth = Number(expiryArr[0]);
      let expiryYear = Number(expiryArr[1]);
      if (expiryMonth > 12) {
        window.alert('Mês de expiração do cartão inválido...');
        this.blockUi(false);
        return;
      }
      if (expiryYear < 2018) {
        window.alert('Ano de expiração do cartão inválido...');
        this.blockUi(false);
        return;
      }

      let label = '****' + cardnumber.slice(-4); // TODO cardFlag + '' + 

      let cardCheckingDTO = {
        "uuid": NEW_CARD,
        "cardholdername": this.state.creditCardDetails.name,
        "cardnumber": cardnumber,
        "cvv": this.state.creditCardDetails.cvv,
        "expiremonth": expiryMonth,
        "expireyear": expiryYear,
        "cardflag": null, // TODO
        "saveCard": this.state.creditCardDetails.saveCard,
        "label": label,

        "cpf": this.state.creditCardDetails.cpf,
        "birthdate": null, // TODO
      };

      // console.log('cardCheckingDTO', cardCheckingDTO); // FIXME : remove it

      let zeroAuthImplemented = false; // TODO ********
      if (!zeroAuthImplemented) {
        this.setState({
            ...this.state,
            creditCardDetails: {
                ...this.state.creditCardDetails,
                valid: true,
            }
        });
        this.close(cardCheckingDTO);

      } else {
        // TODO ********

        backend.validateAndSaveCard(cardCheckingDTO)
            .then(response => {
                this.blockUi(false);
                // console.log('order response', response);
    
                if (this.state.saveCard) {
                    this.setState({
                        ...this.state,
                        creditCardDetails: {
                            ...this.state.creditCardDetails,
                            uuid: NEW_CARD, // TODO response.cardUuid, ********
                            valid: response.valid,
                            msg: response.msg,
                        }
                    }); 
                } else {
                    this.setState({
                        ...this.state,
                        creditCardDetails: {
                            ...this.state.creditCardDetails,
                            valid: response.valid,
                            msg: response.msg,
                        }
                    });
                }
    
                if (this.state.creditCardDetails.valid) {
                    // TODO improve it... =(
                    // window.alert('Dados do cartao verificados com sucesso.');

                    this.close(cardCheckingDTO);
                } else {
                    // TODO improve it... =(
                    window.alert('Erro ao validar cartao. Dados do cartão parecem não estar corretos, se o erro persistir, ' + 
                    'talvez seja necessário entrar em contato com banco emissor do cartão.');
                }
    
            })
            .catch(err => {
                this.blockUi(false);
                console.error('Error validating credit card', err);
    
                if (this.state.saveCard) {
                this.setState({
                    ...this.state,
                    creditCardDetails: {
                        ...this.state.creditCardDetails,
                        uuid: NEW_CARD,
                        valid: false,
                    }
                }); 
                } else {
                this.setState({
                    ...this.state,
                    creditCardDetails: {
                        ...this.state.creditCardDetails,
                        uuid: NEW_CARD,
                        valid: false,
                    }
                    });
                }
    
                // TODO improve it... =(
                window.alert('Erro ao validar cartao. Dados do cartão parecem não estar corretos, se o erro persistir, ' + 
                'talvez seja necessário entrar em contato com banco emissor do cartão.');
    
            });
      }

    }

    deleteCCard = (card) => {
        this.blockUi(true);
        // console.log('card', card); // TODO remove it 

        backend.deleteVisitorCCard(card)
            .then(response => {
                this.blockUi(false);

                this.setState({
                    ...this.state,
                    cards: this.state.cards.filter(function(cardItem) { 
                        return cardItem.cardHash !== card.cardHash
                    }
                )});

            }).catch(err => {
                this.blockUi(false);
                console.error('Error deleting credit card', err);
    
                // TODO improve it... =(
                window.alert('Não foi possível deletar este cartão. Caso erro persista, favor entrar com contato com nosso atendimento.');
            });

    }

    close = (cardCheckingDTO) => {
        this.props.onHandleClose({
            cardDetails: cardCheckingDTO,
        });
        return;
    }

    cancel = () => {
        let cardCheckingDTO = {
            uuid: NEW_CARD,
            label: '',
        }
        this.close(cardCheckingDTO);
        return;
    }

    renderCards = () => {
        const cardOptions = [];
        if (this.state.cards) {
            this.state.cards.map((card) => {
                let label = card.cardFlag + ' ' + card.cardLabel;
                cardOptions.push(
                    <section key={card.cardHash}>
                        <FormControlLabel value={card.cardHash} control={<Radio />} 
                            label={label} key={card.cardHash} />

                        <Button onClick={() => this.deleteCCard(card) } 
                                style={{'backgroundColor': '#ef5350'}}>
                            Delete
                        </Button>
                    </section>
                );
            });
        }
        return cardOptions;
    }

    render() {
       const { classes } = this.props;
       return <section>
           <Dialog fullScreen
                open={this.props.modalOpen} 
                onClose={this.cancel} 
                aria-labelledby="form-dialog-title"
            >
                <BlockUi tag="div" blocking={this.state.blocking}>
                <DialogTitle id="form-dialog-title">Payaqui</DialogTitle>
                <DialogContent>


                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Selecionar Cartão de Crédito </FormLabel>
                            <RadioGroup
                                aria-label="Selecionar Cartão de Crédito"
                                name="selectedCreditCard"
                                id="selectedCreditCard"
                                className={classes.group}
                                value={this.state.creditCardDetails.uuid}
                                onChange={this.handleRadioGroupChange}
                            >

                                { this.renderCards() }

                                <FormControlLabel value={NEW_CARD} control={<Radio />} label="Adicionar Novo" />

                            </RadioGroup>
                        </FormControl>


                        { this.state.creditCardDetails.uuid === NEW_CARD ? (
                        <div>
                            <Cards
                                number={this.state.creditCardDetails.number}
                                name={this.state.creditCardDetails.name}
                                expiry={this.state.creditCardDetails.expiry}
                                cvc={this.state.creditCardDetails.cvv}
                                focused={this.state.creditCardDetails.focused}
                            />
                            <div>
                                <InputMask 
                                    mask="9999 9999 9999 9999" 
                                    maskChar=" "
                                    value={this.state.creditCardDetails.number}
                                    onChange={this.handleCreditCardDetailChange}
                                    onFocus={this.handleInputFocus}
                                >
                                {() => <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="number"
                                        label="Número do cartão"
                                        name="number"
                                        autoComplete="number"
                                        InputProps={{
                                          type: 'tel',
                                        }}
                                    /> }
                                </InputMask>
                            </div>
                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nome"
                                    name="Nome"
                                    autoComplete="nome"
                                    value={this.state.creditCardDetails.name}
                                    onChange={this.handleCreditCardDetailChange}
                                    onFocus={this.handleInputFocus}
                                />
                            </div>
                            <div>
                                <InputMask 
                                    mask="99/9999"
                                    value={this.state.creditCardDetails.expiry}
                                    onChange={this.handleCreditCardDetailChange}
                                    onFocus={this.handleInputFocus}
                                >
                                {() => <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="expiry"
                                        label="Mês/Ano Expiração"
                                        name="expiry"
                                        autoComplete="expiry"
                                        style={{width: '45%'}}
                                        InputProps={{
                                          type: 'tel',
                                        }}
                                    /> }
                                </InputMask>
                                &nbsp;
                                <InputMask 
                                    mask="999"
                                    value={this.state.creditCardDetails.cvv}
                                    onChange={this.handleCreditCardDetailChange}
                                    onFocus={this.handleInputFocus}
                                >
                                {() => <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="cvv"
                                        label="Cód. de Segurança"
                                        name="cvv"
                                        autoComplete="cvv"
                                        style={{width: '45%'}}
                                        InputProps={{
                                          type: 'tel',
                                        }}
                                    /> }
                                </InputMask>
                            </div>
                            <div>
                                <InputMask 
                                    mask="999.999.999-99"
                                    maskChar=" "
                                    value={this.state.creditCardDetails.cpf}
                                    onChange={this.handleCreditCardDetailChange}
                                    onFocus={this.handleInputFocus}
                                >
                                {() => <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="cpf"
                                        label="CPF do titular do cartão"
                                        name="cpf"
                                        autoComplete="cpf"
                                        InputProps={{
                                          type: 'tel',
                                        }}
                                    /> }
                                </InputMask>
                            </div>


                            <div>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        id="saveCard"
                                        checked={this.state.creditCardDetails.saveCard}
                                        onChange={this.handleCreditCardDetailChange}
                                        value="true"
                                        color="primary"
                                    />
                                }
                                label="Salvar Cartão"
                                />
                            </div>

                            {/* <div>
                                <Button onClick={ this.validateAndSaveNewCard }
                                    color='secondary' variant="contained" className={classes.actionButtom}
                                >
                                Salvar Novo Cartao
                                </Button>
                            </div> */}

                        </div>
                        ) : (
                            <span></span>
                        )}


                </DialogContent>

                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.continue} color="primary">
                        Continuar
                    </Button>
                </DialogActions>

                </BlockUi>
           </Dialog>
       </section>
       }

       blockUi = (block) => {
         this.setState({...this.state, blocking: block});
       }
 }

 export default withStyles(styles)(PaymentMethodFormModal);