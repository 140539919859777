import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import currencyFormatter from 'currency-formatter';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import TextField from '@material-ui/core/TextField';
import BottomNavigation from '@material-ui/core/BottomNavigation';

// import NumberFormat from 'react-number-format';

// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  bottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: '80px',
    textAlign: 'center',
    verticalAlign: 'bottom',
  },

  productLogo: {
    borderRadius: '10px',
  },

  productControls: {
    verticalAlign: 'middle', // FIXME: it doesn't work...
  },
  qtyIconBtn: {
    height: 60,
    width: 60,
  },
  
});

class StandProducts extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,
        standDTO: props.location.state.standDTO,
        products: [],
        totalOrderAmountInCents: 0,

        bottomNavSelected: 0,
      };
    }

    componentWillMount() {
       fire.auth().onAuthStateChanged(user => {
           this.setState({
               ...this.state,
               isSignedIn: !!user,
               userFirebaseUid: user.uid,
           });

           // console.log("user", user);
           // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
        this.fetchStandProducts();
    }

    fetchStandProducts = () => {
        let standId = this.state.standDTO.id;
        backend.getStandProducts(standId)
            .then(data => {
                // console.log('eventStands', data);
                data = data.map((p) => {
                  p.cartQuantity = 0;
                  return p;
                });
                this.setState({
                    products: data,
                });
            }).catch((err) => {
                console.error('Error fetching stand products.', err);
                throw err;
            });
    }

    addProductToCart = (productDTO) => {
      let { products } = this.state;
      products = products.map((productDTOItem) => {
        if (productDTO.id === productDTOItem.id) {
          productDTO.cartQuantity++;
          return productDTO;
        } else {
          return productDTOItem;
        }
      });
      this.setState({
        ...this.state,
        products,
      });
      this.calculateTotalOrderAmout();
    }

    removeProductFromCart = (productDTO) => {
      let { products } = this.state;
      products = products.map((productDTOItem) => {
        if (productDTO.id === productDTOItem.id) {
          if (productDTO.cartQuantity > 0) {
            productDTO.cartQuantity--;
          }
          return productDTO;
        } else {
          return productDTOItem;
        }
      });
      this.setState({
        ...this.state,
        products,
      });
      this.calculateTotalOrderAmout();
    }

    calculateTotalOrderAmout = () => {
      let totalInCents = 0;
      this.state.products.map((productDTOItem) => {
        if (productDTOItem.cartQuantity > 0) {
          totalInCents += productDTOItem.cartQuantity * productDTOItem.valueInCents;
        }
        return productDTOItem;
      });
      this.setState({
        ...this.state,
        totalOrderAmountInCents: totalInCents,
      });
    }

    confirm = () => {
      // console.log('state', this.state); // FIXME remove it
      this.goToOrderConfirmationPage();
    }

    refresh = () => {
        // window.location.reload();
        this.fetchStandProducts();
    }

    renderProducts = () => {
        const { classes } = this.props;
        let productList = [];
        if (this.state.products) {
            this.state.products.map((productDTO) => {
                if (!productDTO.active || !productDTO.standHandleOrders) {
                  return;
                }
                productList.push(
                  <Grid item xs={12} md={6} key={productDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                        <center>
                        <Typography color='secondary' gutterBottom>
                            { productDTO.name } 
                        </Typography>
                        </center>
                      <div>
                          <div>
                              <center>
                              { productDTO.logoUrl ? (
                                <img src={productDTO.logoUrl} 
                                  width="80%" alt="payaqui" 
                                  className={classes.productLogo} />
                              ) : (
                                <span></span>
                              )}
                              </center>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Descrição: </label>
                              <span className={classes.fieldValue}>{productDTO.description}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Loja: </label>
                              <span className={classes.fieldValue}>{productDTO.standName}</span>
                          </div>
                          {/* <div>
                              <label className={classes.fieldName}>Display Order: </label>
                              <span className={classes.fieldValue}>{productDTO.displayOrder}</span>
                          </div> */}
                          <div>
                              <label className={classes.fieldName}>Preço: </label>
                              <span className={classes.fieldValue}>
                                { katanaUtil.formatDecimal(productDTO.value) }
                              </span>
                          </div>
                          {/* <div>
                              <label className={classes.fieldName}>Tempo estimado de preparo: </label>
                              <span className={classes.fieldValue}>+-{productDTO.avgPreparationTime}</span>
                          </div> */}
                          <div>
                              { productDTO.standHandleOrders ? 
                                  <center>
                                  <div className={classes.productControls}>
                                    <IconButton aria-label="Remover" onClick={() => this.removeProductFromCart(productDTO)}>
                                      <RemoveRoundedIcon color='primary' className={classes.qtyIconBtn} />
                                    </IconButton>
                                    <TextField
                                      value={productDTO.cartQuantity}
                                      variant="outlined"
                                      margin="normal"
                                      id="qty"
                                      label="Qtde"
                                      name="qty"
                                      autoComplete="qty"
                                      style={{width: '50px'}}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                    <IconButton aria-label="Adicionar" onClick={() => this.addProductToCart(productDTO)}>
                                      <AddRoundedIcon color='primary' className={classes.qtyIconBtn} />
                                    </IconButton>
                                  </div>
                                  </center>
                              :  
                                  <span></span>
                              }
                          </div>
                      </div>
                      
                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return productList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    edge="start"
                    onClick={() => this.goBack()}
                    className={classes.menuButton}
                  >
                    <ArrowBack />
                  </IconButton>

                  <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                  {/* <Typography variant="h6" noWrap>
                    Bla
                  </Typography> */}
                </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography color='secondary' gutterBottom>
                          {this.state.eventDTO.name} - {this.state.standDTO.name} - Produtos
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderProducts() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>


              <BottomNavigation
                className={classes.bottomNav}
                showLabels={false}
              >
                <div style={{marginTop: '5px'}}>
                  <b>Total:</b> { katanaUtil.formatDecimalInCents(this.state.totalOrderAmountInCents) } 
                  <br />
                  <Button onClick={() => this.confirm()} 
                      color='primary' variant="contained" className={classes.actionButtom}
                  >
                  Comprar
                  </Button>
                </div>
              </BottomNavigation>

              </div>

            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}

        </div>
      </React.Fragment>
      )
    }

    goBack = (e) => {
      this.props.history.push({
          pathname: '/event-stands',
          state: { eventDTO: this.props.location.state.eventDTO }
      });
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goToStandProducts = (standDTO) => {
      this.props.history.push({
          pathname: '/stand-products',
          state: { 
            eventDTO: this.state.eventDTO,
            standDTO,
          }
      });
    }

    goToOrderConfirmationPage = () => {
      this.props.history.push({
          pathname: '/order-products-form',
          state: { 
            eventDTO: this.state.eventDTO,
            standDTO: this.state.standDTO,
            products: this.state.products,
            totalOrderAmountInCents: this.state.totalOrderAmountInCents,
          }
      });
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(StandProducts));