import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
// import firebase from "firebase";

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BlockUi from 'react-block-ui';

import 'react-block-ui/style.css';
// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152,
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
      color: theme.palette.text.secondary,
  },
  appStoreBtn: {
    textTransform: 'none',
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    width: 152,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 100,
  },
});

class Home extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        userFirebaseRa: '',
        userFirebaseRefreshToken: '',
        userFirebaseNotificationToken: '',
        userBackendToken: '',
        visitorDetails: {
            name: '',
            email: '',
            documentNumber: '',
            phoneNumber: '',
            photoURL: '',
        },

        events: [],
        blocking: false,
      };
    }

    uiConfig = {
        // signInFlow: "popup",
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          { 
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            defaultCountry: 'BR'
          },
          // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          signInSuccessWithAuthResult: () => false
        }
    };

    componentWillMount() {
        this.blockUi(true);
        fire.auth().onAuthStateChanged(user => {
            this.blockUi(false);
            if (!user) {
              this.logout();
              return;
            }

            this.setState({ 
                isSignedIn: !!user,
                userFirebaseUid: user.uid,
                userFirebaseRa: user.ra,
                userFirebaseRefreshToken: user.refreshToken,
                visitorDetails: {
                    ...this.state.visitorDetails,
                    name: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    photoURL: user.photoURL,
                },
            });
            localStorage.setItem('uid', user.uid);

            this.getBackendToken();
            this.getNotificationToken();
            this.fetchVisitorDetails();

            this.fetchEvents(); // FIXME

            // console.log("user", user);
        });

        // console.log('appName', fire.auth().currentUser.u);
    }

    logout = () => {
        fire.auth().signOut();
        this.setState({ 
            isSignedIn: false,
        });
        localStorage.removeItem('uid');
        localStorage.removeItem('backendJwt');
    }

    fetchVisitorDetails = () => {
        backend.getOpenUser()
            .then(data => {
                this.setState({
                    visitorDetails: {
                        ...this.state.visitorDetails,
                        name: data.name,
                        email: data.email,
                        documentNumber: data.documentNumber,
                        phoneNumber: data.phoneNumber,
                    },
                });

                // console.log('visitorDetails', this.state.visitorDetails);

            }).catch((err) => {
                // console.error('Error fetching visitor details.', err);
                // throw err;

                this.goToVisitorDetailForm();

            });
    }

    fetchEvents = () => {
        backend.getOpenEvents()
            .then(data => {
                this.setState({
                    events: data,
                });
            }).catch((err) => {
                console.error('Error fetching events.', err);
                throw err;
            });
    }

    // TODO check how it works async process in react
    // fetchEvents = async () => {
    //     let events = [];
    //     try {
    //         events = await backend.getOpenEvents();
    //     } catch (err) {
    //         console.error('Error fetching events.', err);
    //         throw err;
    //     }
    //     return events;
    // }

    getBackendToken = () => {
        let token = this.state.userFirebaseRa;
        backend.authVisitor(token)
            .then(data => {
                this.setState({
                    userBackendToken: data.id_token,
                });
                localStorage.setItem('backendJwt', data.id_token);
                // console.log('state', this.state); // TODO remove it
            }).catch((err) => {
                console.error('Error fetching events.', err);
                throw err;
            });
    }
    
    getNotificationToken = () => {
        this.doGetNotificationToken();
    }

    async doGetNotificationToken() {
        try {
            const messaging = fire.messaging();
            await messaging.requestPermission();
            const token = await messaging.getToken();
            // console.log('notificationToken:', token);
            this.setState({
                userFirebaseNotificationToken: token,
            });
            this.refreshToken();
          return token;
        } catch (error) {
            console.error('Error getting firebase notification token.', error);
        }
    }

    refreshToken = () => {
        let pushTokenRefreshDTO = {
            registrationToken: this.state.userFirebaseNotificationToken,
        }
        backend.refreshToken(pushTokenRefreshDTO);
    }

    joinEvent = (eventDTO) => {
        this.blockUi(true);
        // console.log('join event', eventDTO)
        if (eventDTO.ticket == null) {
            backend.joinEvent(eventDTO.id)
                .then(ticketIssueResponseDTO => {
                    this.blockUi(false);
                    // console.log('ticketIssueResponseDTO', ticketIssueResponseDTO);
                    eventDTO.ticket = ticketIssueResponseDTO.t;
                    this.goToEvent(eventDTO);
                })
                .catch(err => {
                    this.blockUi(false);
                    console.error('Error trying to join an event', err);
                });
        } else {
            console.warn('Visitor already has ticket to this event. ticket=' + eventDTO.ticket);
        }
    }

    goToEvent = (eventDTO) => {

      if (eventDTO.sellPassports) {
        this.props.history.push({
          pathname: '/event-details',
          // search: '?query=abc',
          state: { eventDTO }
      });
      } else {
        if (eventDTO.paymentType === 'PREPAID') {
            this.props.history.push({
                pathname: '/event-wallet',
                // search: '?query=abc',
                state: { eventDTO }
            });
        } else {
            this.props.history.push({
                pathname: '/event-stands',
                state: { eventDTO }
            });
        }
      }
    }

    refresh = () => {
        // window.location.reload();
        this.fetchVisitorDetails();
        this.fetchEvents();
    }

    renderEventList = () => {
        const { classes } = this.props;
        let eventList = [];
        if (this.state.events) {
            this.state.events.map((eventDTO) => {
                eventList.push(
                  <Grid item xs={12} md={6} key={eventDTO.id}>
                  <Paper className={classes.paper}>
                    <div className={classes.box}>
                      
                      <Typography color='secondary' gutterBottom>
                        {eventDTO.name}
                      </Typography>
                      <div>
                        {/* <div>
                            <label className={classes.fieldName}>Id: </label>
                            <span className={classes.fieldValue}>{eventDTO.id}</span>
                        </div> */}
                        <div>
                            <label className={classes.fieldName}>Data Inicio: </label>
                            <span className={classes.fieldValue}>{ katanaUtil.formatDateDDMMYYYY(eventDTO.start) }</span>
                        </div>
                        <div>
                            <label className={classes.fieldName}>Data Fim: </label>
                            <span className={classes.fieldValue}>{ katanaUtil.formatDateDDMMYYYY(eventDTO.end) }</span>
                        </div>
                        {/* <div>
                            <label className={classes.fieldName}>Tipo: </label>
                            <span className={classes.fieldValue}>{eventDTO.paymentType}</span>
                        </div> */}
                        {/* <div>
                            <label className={classes.fieldName}>Compra Creditos Online: </label>
                            <span className={classes.fieldValue}>{eventDTO.topupEnabled}</span>
                        </div> */}
                        {/* eventDTO.logoUrl */}
                        {/* eventDTO.ticket */}
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { eventDTO.ticket == null ? 
                            <Button onClick={() => this.joinEvent(eventDTO)} 
                                color='primary' variant="contained" className={classes.actionButtom}
                            >
                            Participar do evento
                            </Button>
                        :  
                            <Button onClick={() => this.goToEvent(eventDTO)} 
                                color='primary' variant="contained" className={classes.actionButtom}
                            >
                            Ver evento
                            </Button>
                        }
                    </div>
                  </Paper>
                </Grid>
                );
            });
        }
        return eventList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>
                        
            {this.state.isSignedIn ? (
              <div>

              <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    {/* <IconButton
                      color="inherit"
                      aria-label="Voltar"
                      edge="start"
                      onClick={() => this.showMenu()}
                      className={classes.menuButton}
                    >
                      <MenuIcon />
                    </IconButton> */}

                    <img src="/logo_white_splash.png" 
                      height="30" alt="payaqui" 
                      className={classes.menuLogo} />

                    {/* <Typography variant="h6" noWrap>
                      Bla
                    </Typography> */}
                  </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>
                    
                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12}>
                    <Paper className={classes.paper} style={{backgroundColor: '#f0f4c3'}} >
                      <div className={classes.box}>
                        
                        <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
                          { !this.state.visitorDetails.photoURL ? (
                             <span></span>
                          ) : (
                            <img src={ this.state.visitorDetails.photoURL } 
                                alt="profilePhoto" width="50px" 
                                style={{display: 'inline', paddingRight: '10px'}} 
                            />
                          )}
                          { this.state.visitorDetails.name }
                        </Typography>
                        <div>
                          <div>
                              <label className={classes.fieldName}>Email: </label>
                              <span className={classes.fieldValue}>{this.state.visitorDetails.email}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Celular: </label>
                              <span className={classes.fieldValue}>{this.state.visitorDetails.phoneNumber}</span>
                          </div>
                          {/* <div>
                              <label className={classes.fieldName}>CPF: </label>
                              <span className={classes.fieldValue}>{this.state.visitorDetails.documentNumber}</span>
                          </div> */}
                          {/* 
                          <div>
                              <label className={classes.fieldName}>userFirebaseNotificationToken: </label>
                              <span className={classes.fieldValue}>{this.state.userFirebaseNotificationToken}</span>
                          </div> 
                          */}
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        <Button onClick={() => this.logout()} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Logout
                        </Button>
                        <Button onClick={() => { this.refresh() }} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Atualizar
                        </Button>
                        <Button onClick={() => { this.goToVisitorDetailForm() }} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Meus Dados
                        </Button>

                        {/* 
                        <Button onClick={() => this.getNotificationToken()} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          Request Push Permission
                        </Button> 
                        
                        <Button onClick={this.test} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          TEST
                        </Button> 
                        */}

                      </div>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderEventList() }
                </Grid>

               {/* for debug */}
               {/* <div className="bla">
                   <button onClick={this.test}>TEST</button>
                   <br />
                   <a href="/bla">Bla</a>
               </div>  */}
               

              </Grid>
              </div>
            ) : (
              <div> 
                <center>
                <div 
                  style={{
                    backgroundColor: '#00897b', 
                    borderRadius: '10px', 
                    width: '200dp', 
                    margin: '20px 20px 5px 20px',
                  }}
                >
                    <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                </div>
                <Typography 
                    color='secondary'
                    style={{
                      fontWeight: '600', // semi-bold
                      marginBottom: '45px',
                    }}
                    gutterBottom>
                  Sua Carteira Digital nos melhores eventos!
                </Typography>

                {!this.state.blocking ? (
                  <div>
                    <Typography 
                        color='secondary'
                        gutterBottom>
                      Acesse agora mesmo pelo navegador:
                    </Typography>
                    <StyledFirebaseAuth
                        uiConfig={this.uiConfig}
                        firebaseAuth={fire.auth()}
                    />

                    <Typography 
                        color='secondary'
                        style={{
                          marginTop: '50px',
                        }}
                        gutterBottom>
                      ou baixe os aplicativos disponíveis:
                    </Typography>
                    <div>
                      <Button 
                        color='primary' 
                        variant="contained"
                        className={classes.appStoreBtn}
                        href="https://play.google.com/store/apps/details?id=com.payaqui.katana"
                        target="_blank" 
                      >
                        <i  className={['fa', 'fa-android'].join(' ')} style={{fontSize: "20px"}}></i> &nbsp; Play Store
                      </Button>
                      <br />
                      <Button 
                        color='primary' 
                        variant="contained"
                        className={classes.appStoreBtn}
                        href="https://itunes.apple.com/app/id1392203751"
                      >
                        <i className={['fa', 'fa-apple'].join(' ')} style={{fontSize: "20px"}}></i> &nbsp; Apple Store
                      </Button>
                    </div>
                    

                    <hr style={{color: '#004d40', marginTop: '20px'}} />
                    <Typography 
                        color='secondary'
                        gutterBottom>
                      Para mais informações acesse: <br/>
                      <a href="https://payaqui.com/" target="_blank" rel="noopener noreferrer">
                        https://payaqui.com/
                      </a>
                    </Typography>
                  </div>
                ) : (
                  <span></span>
                )}
                
                </center>
              </div>
            )}
          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goToVisitorDetailForm = (e) => {
        this.props.history.push({
            pathname: '/visitor-detail-form',
            state: {
                userFirebaseNotificationToken: this.state.userFirebaseNotificationToken, // should be saved at redux store...
            },
        });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(Home));