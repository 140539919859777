import axios from 'axios';

const hostname = window && window.location && window.location.hostname;
let backendHost;

// console.log('backend hostname: ', hostname);
if (hostname === 'payaqui.app' 
    || hostname === 'payaqui.web.app' 
    || hostname === 'payaqui.firebaseapp.com') {
  backendHost = 'https://api.payaqui.com';
} else if (hostname === 'payaqui-hom.web.app' 
    || hostname === 'payaqui-hom.firebaseapp.com' 
    || hostname === 'hom.payaqui.app') {
  backendHost = 'https://hom.payaqui.com';
} else if (hostname === 'localhost' 
    || hostname === 'contego.serveo.net') {
  // backendHost = 'http://localhost:8080';
  // backendHost = 'https://b6af3dcc.ngrok.io';
  backendHost = 'https://hom.payaqui.com';
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://hom.payaqui.com';
}
const apiUrl = `${backendHost}/api/`;

const client = axios.create({
  baseURL: apiUrl,
  json: true,
});

export default {

  async execute(method, resource, data, params) {
    // console.log('api.execute()', {method, resource, data, params}); debug

    return client({
      method,
      url: resource,
      data,
      headers: {
        'X-SOURCE': 'com.payaqui.web.katana',
        'X-USER-UID': localStorage.getItem('uid'),
        Authorization: `Bearer ${localStorage.getItem('backendJwt')}`,
      },
      params,
    }).then(req => req.data).catch((err) => {
      if (err.response.status === 401) {
        console.error('Unauthorized, invalid token.');

        // store.modules.user.actions.logout(); // TODO : how to call?
        localStorage.removeItem('uid');
        localStorage.removeItem('backendJwt');

        // TODO if is from /Login -> throw err

        // TODO go home
        // router.go({ path: '/', force: true });

      }

      console.error('Error calling backend.', err);
      throw err;
    });
  },


  // /////////////////////////////////////////////////////
  // User

  // receives: 'eyJhbGciOiJSUzI1NiIsIm....'
  // returns: {"id_token" : "eyJhbGciOiJ...."}
  authVisitor(token) {
    return this.execute('post', '/v1/auth-visitor', token);
  },

  // receives: {registrationToken: 'd9SHv8brrtw:APA91bErE4dkXrWam....'}
  // returns: nothing
  refreshToken(pushTokenRefreshDTO) {
    return this.execute('post', '/v0/open/user-token-refresh', pushTokenRefreshDTO);
  },

  // receives: nothing
  // returns: { "name" : "",  "documentNumber" : "",  "email" : "",  "phoneNumber" : "" }
  getOpenUser() {
    return this.execute('get', '/v0/open/user');
  },

  // receives: {    "documentNumber": "123",    "email": "renanyukio@gmail.com",  "firebaseUID": 'ff32kl3k2l313d',   "eventId": null (this must be null),
  //            "name": "Renan Yukio",    "phoneNumber": "456",    "registrationToken": "d9SHv8brrtw:APA91bE..."}
  // returns: {  "valueCents" : 0,  "timestamp" : 1558113086727,  "issuedBy" : null,  "t" : "68c1001799864f2f5e3904985b1c11c7052b0291ac379a69eba56772a17655a3" }
  createOrUpdateEventVisitor(eventVisitorDTO) {
    return this.execute('post', '/v1/event-visitors', eventVisitorDTO);
  },

  // TODO
  // receives: nothing
  // returns: { "name" : "",  "documentNumber" : "",  "email" : "",  "phoneNumber" : "" }
  // getEventVisitorDetails() { 
  //   return this.execute('get', '/v1/event-visitors');
  // },

  getVisitorCards() {
    return this.execute('get', '/v0/visitor-braspag-saved-cards/');
  },

  deleteVisitorCCard(cardDTO) {
    return this.execute('post', '/v0/visitor-braspag-saved-cards/delete', cardDTO);
  },

  validateAndSaveCard(cardCheckingDTO) {
    return this.execute('post', '/v0/validate-and-save-card', cardCheckingDTO);
  },

  // User
  // /////////////////////////////////////////////////////



  // /////////////////////////////////////////////////////
  // Events

  // receives: nothing
  // returns: [ {  "id" : 1101,  "name" : "fdafds",  "start" : "2019-05-01",  "end" : "2020-05-01",
  //            "ticket" : null,  "topupEnabled" : true,  "paymentType" : "PREPAID",  "logoUrl" : null } ]
  getOpenEvents() {
    return this.execute('get', '/v0/open/events');
  },

  getOpenEventById(eventId) {
    return this.execute('get', `/v0/open/event/${eventId}`);
  },

  // receives: {    "documentNumber": "123",    "email": "renanyukio@gmail.com", "firebaseUID": 'ff32kl3k2l313d',
  //            "name": "Renan Yukio",    "phoneNumber": "456",    "registrationToken": "d9SHv8brrtw:APA91bE..."}
  // returns: {  "valueCents" : 0,  "timestamp" : 1558113086727,  "issuedBy" : null,  "t" : "68c1001799864f2f5e3904985b1c11c7052b0291ac379a69eba56772a17655a3" }
  joinEvent(eventId) {
    return this.execute('post', `/v1/join-event/${eventId}`, {});
  },

  // receives: { "t": "68c1001799864f2f5e3904985b1c11c7052b0291ac379a69eba56772a17655a3" }
  // returns: [ {  "id" : 22606,  "valueCents" : -79000,  "timestamp" : 1556134834577,
  //            "description" : "Pedido Online",  "status" : "PENDING",  "products" : null,
  //            "transactionProducts" : [ {    "id" : 22854,    "qtde" : 2,    "unitPrice" : 340.00,
  //               "totalValue" : 680.00,    "transactionId" : 22606,    "productId" : 22401,    "productName" : "Camisa Seleção",
  //               "eventId" : 22301,    "eventName" : "Rock in Rio em São Paulo",    "standId" : 22351,    "standName" : "Camiseteria",
  //               "unitPriceInCents" : 34000,    "totalValueInCents" : 68000  } ]
  getEventVisitorTransactions(ticketDTO) {
    return this.execute('post', `/v0/transactions/`, ticketDTO);
  },

  // receives: { "t": "68c1001799864f2f5e3904985b1c11c7052b0291ac379a69eba56772a17655a3" }
  // returns: {  "valueCents" : 0,  "timestamp" : 1558113341193 }
  getEventVisitorBalance(ticketDTO) {
    return this.execute('post', '/v0/get-balance', ticketDTO);
  },

  // receives: { "convenienceFee": null, "creditValue": 5000, "eventId": 22301, "voucher": null,
  //             "device": { "appVersionCode": "1.5.1.23", "deviceId": null,
  //                      "lat": "0.0", "lng": "0.0", "phone": null, "platform": "android" }, 
  //             "payment": { "birthdate": null, "cardflag": null, "cardholdername": "JOHN DOE",
  //                      "cardnumber": "5454545454545454", "cpf": "12312345699", "cvv": "123",
  //                      "expiremonth": 12, "expireyear": 2025, "paymenttype": "CREDIT_CARD" },
  //              "ticket": "f17e810f7c5426fa34c1bbeb4d274474575e934f8ec2bbc47a21e63d073a5ea1", 
  //              "totalAmount": 5000,
  //              "visitor": { "email": "renanyukio@gmail.com", "phone": "88888888876"} }
  // returns: { "status": "COMPLETED", "statusCode": 4, "msg": "Transação realizada com sucesso!",
  //            "detail": null, "errors": [], "transactionId": 30001, "amount": 5000,
  //            "receiptCode": "20190607094530877", "transactionDateTime": "2019-06-07T09:45:31.098-03:00" }
  topUpWithCreditCard(topUpDTO) {
    return this.execute('post', '/v0/order/place/value/', topUpDTO);
  },

  // receives: nothing
  // returns: [ { "id" : 1552,  "name" : "churrasco",  "handleOrders" : true,  "logoUrl" : "https://bla.com/logo-nara.PNG",
  //              "description" : "bla bla\n bla...",  "active" : true,  "preparationStepEnabled" : false,  "eventId" : 1901,
  //              "eventName" : "festival",  "closed" : false } ]
  getEventStands(eventId) {
    return this.execute('get', `/v1/open/events/${eventId}/stands`);
  },

  // receives: nothing
  // returns: [ { "createdBy" : "admin", "createdDate" : "2019-06-11T19:37:36.278Z", "lastModifiedBy" : "admin", "lastModifiedDate" : "2019-06-11T19:37:36.278Z",
  //              "id" : 1951, "name" : "Espeto de carne", "value" : 3.00, "sku" : "123", "active" : true, "standName" : "churrasco", "logoUrl" : "https://bla.com/carne.jpg",
  //              "description" : "bla \nbla...", "avgPreparationTime" : 5, "displayOrder" : "01", "standId" : 1552, "eventId" : 1901, "eventName" : "festival",
  //              "standHandleOrders" : true, "valueInCents" : 300 } ]
  getStandProducts(standId) {
    return this.execute('get', `/v0/open/stands/${standId}/products`);
  },

  // receives: { "details": null, "eventId": 1901, "paymentType": "POSTPAID", "device": {
  //                "appVersionCode": "1.5.2.24", "deviceId": null,
  //                "lat": "0.0", "lng": "0.0", "phone": null, "platform": "web" },
  //             "payment": { "birthdate": null, "cardflag": null, "cardholdername": "JOHN DOE", "cardnumber": "5454545454545454",
  //                "cpf": "123", "cvv": "123", "expiremonth": 12, "expireyear": 2025, "paymenttype": "CREDIT_CARD" },
  //             "requestDatetime": 1560282086838, "standId": 1552, "ticket": "3bde....", "totalAmount": 1900, "transactionDesc": "Pedido Online",
  //             "transactionOrderId": null, "visitor": {"email": "renanyukio@gmail.com", "phone": "123" },
  //             "visitorNote": null, "voucher": null,   "transactionProducts": [ {
  //                "eventId": 1901, "eventName": null, "id": null, "productId": 1951,
  //                "productName": "Espeto de carne", "qtde": 1, "standId": 1552, "standName": null,
  //                "totalValueInCents": 300, "transactionId": null, "unitPriceInCents": 300 } ] }
  // returns: { "transactionOrderId": 1856, "totalAmount": 1900, "paymentType": null, "status": "CREATED", "statusCode": 0,
  //            "createdDatetime": "2019-06-11T16:41:28.886-03:00", "updatedDatetime": "2019-06-11T16:41:28.917-03:00",
  //            "transactionDesc": "Pedido Online", "paymentReceiptCode": "0611044131168", "walletBalance": null, "msg": "Pedido recebido com sucesso! #1856",
  //            "msgCode": 2, "details": null, "retriableError": null }
  createProductOrder(productOrderDTO) {
    return this.execute('post', '/v0/new-visitor-order', productOrderDTO);
  },

  // receives: nothing
  // returns: [ {} ]
  getEventVisitorOrders(eventId) {
    return this.execute('get', `/v0/visitor-orders/${eventId}`);
  },

  // receives: nothing
  // returns: {}
  getVisitorOrderDetail(transactionId) {
    return this.execute('get', `/v0/visitor-transaction/${transactionId}`);
  },

  transferCredits(transferDTO) {
    return this.execute('post', '/v0/wallet/transfer-value', transferDTO);
  },

  // receives: nothing
  // returns: [ {} ]
  getEventPassportDefinitions(eventId) {
    return this.execute('get', `/v0/open/events/${eventId}/passport-definitions`);
  },

  // receives: nothing
  // returns: [ {} ]
  getMyEventPassports(eventId) {
    if (eventId && eventId > 0) {
      return this.execute('get', `/v0/passports/my-passports/${eventId}`);
    } else {
      return this.execute('get', '/v0/passports/my-passports/all');
    }
  },

  createPassportOrder(passportOrderDTO) {
    return this.execute('post', '/v0/passports/purchase', passportOrderDTO);
  },

  // receives: nothing
  // returns: [ {} ]
  getMyPassportById(passportId) {
    return this.execute('get', `/v0/passports/byId/${passportId}`); // TODO implement endpoint in the backend
  },

  // Events
  // /////////////////////////////////////////////////////



  // /////////////////////////////////////////////////////
  // dev, temp, tests
  test() {
    return this.execute('get', '/v0/test');
  },
  // dev, temp, tests
  // /////////////////////////////////////////////////////

};
