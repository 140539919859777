import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';

import PaymentMethodFormModal from '../components/PaymentMethodFormModal';

import 'react-block-ui/style.css';

const appBarHeight = 55;

const NO_CARD_SELECTED = 'Favor selecionar...';
const NEW_CARD = 'Novo cartão';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 200
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
      color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  validationMsgs: {
    fontSize: '10pt',
    color: '#ff0000',
    // color: theme.palette.text.secondary,
  },

  birthDateFieldName: {
    fontSize: '10pt',
    marginTop: '5pt'
  },

});

class PassportCheckout extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,
        visitorDetails: {
            name: '',
            email: '',
            documentNumber: '',
            phoneNumber: '',
            photoURL: '',
        },
        cards: [],

        passportDefs: props.location.state.passportDefs,
        totalOrderAmount: katanaUtil.formatDecimalInCents(
          props.location.state.totalOrderAmountInCents
        ),
        totalOrderAmountInCents: props.location.state.totalOrderAmountInCents,

        selectedCreditCard: null,
        selectedCreditCardLabel: NO_CARD_SELECTED,
        creditCardDetails: {
          cardholdername: '',
          cardnumber: '',
          cvv: '',
          expiremonth: 0,
          expireyear: 0,
          cardflag: null,
          saveCard: false,
          label: '',
  
          cpf: '',
          birthdate: null,
          email: '',
          phone: '',
        },
        cvvForSavedCard: '',

        passportOrderItems: this.buildPassportOrderItems(props.location.state.passportDefs),

        validationMsgs: null,

        blocking: false,
        modalOpen: false,
      };
    }

    buildPassportOrderItems(passportDefs) {
      let items = [];
      let totalInCents = 0;

      let itemSortId = 1;
      passportDefs.map((passportDefDTO) => {
        for (let i = 0; i < passportDefDTO.cartQuantity; i++) {

          items.push({
            eventName: passportDefDTO.eventName,
            passportDefinitionId: passportDefDTO.id,
            passportDefinitionName: passportDefDTO.name,
            priceInCents: passportDefDTO.priceInCents,
            transactionFeeInCents: passportDefDTO.transactionFeeInCents,
            qty: 1,
            visitorName: '',
            visitorEmail: '',
            visitorPhone: '',
            visitorDocumentType: 'CPF',
            visitorDocumentNumber: '',
            birthDate: null,
            visitorNote: '',
            sortId: itemSortId++,
          });

          totalInCents += passportDefDTO.priceInCents;
        }
        return passportDefDTO;
      });
      // console.log('items', items); // TODO remove it

      // if (totalInCents !== this.state.totalOrderAmountInCents) {
      //   throw new Error({msg: 'Valor total da compra inválido.'});
      // }

      return items;
    }

    componentWillMount() {
      fire.auth().onAuthStateChanged(user => {
          this.setState({
              ...this.state,
              isSignedIn: !!user,
              userFirebaseUid: user.uid,
              visitorDetails: {
                  ...this.state.visitorDetails,
                  photoURL: user.photoURL,
              },
          });
          localStorage.setItem('uid', user.uid);

          this.fetchVisitorDetails();
          this.fetchVisitorCards();
      });
      // console.log('passportOrderItems', this.state.passportOrderItems); // TODO remove it
    }

    fetchVisitorDetails = () => {
        backend.getOpenUser()
            .then(data => {
                this.setState({
                    ...this.state,
                    visitorDetails: {
                        ...this.state.visitorDetails,
                        name: data.name,
                        email: data.email,
                        documentNumber: data.documentNumber,
                        phoneNumber: data.phoneNumber,
                    },
                    creditCardDetails: {
                      ...this.state.creditCardDetails,
                      name: data.name,
                      cpf: data.documentNumber,
                    },
                });
            }).catch((err) => {
                console.error('Error fetching visitor details.', err);
                throw err;
            });
    }

    fetchVisitorCards = () => {
      backend.getVisitorCards()
          .then(data => {
              let defaultCard = {};
              if (data && data.length > 0) {
                const card = data.pop();
                defaultCard = {
                  uuid: card.cardHash,
                  label: card.cardLabel + ' '  + card.cardFlag,
                };
              }

              this.setState({
                  ...this.state,
                  cards: data,
                  selectedCreditCard: defaultCard.uuid,
                  selectedCreditCardLabel: defaultCard.label,
              });
          }).catch((err) => {
              console.error('Error fetching visitor cards.', err);
              throw err;
          });
    }

    handleChange = (event) => {
      // console.log('event.target.id = ' +  event.target.id); // TODO remove it
      this.setState({
        ...this.state,
        [event.target.id]: event.target.value,
      });
    }

    handleCurrencyField = (event) => {
      let amountInCentsStr = event.target.value.replace(/[,\\.]/g, '');
      let amountInCents = Number(amountInCentsStr);
      this.setState({
        ...this.state,
        totalOrderAmount: katanaUtil.formatDecimalInCents(amountInCents),
        totalOrderAmountInCents: amountInCents,
      });
    }

    handleSubmit = (event) => {
       event.preventDefault();
       this.placeOrder();
    }

    // TODO improve it... =(
    placeOrder = () => {
      this.blockUi(true);

      // console.log('state', this.state); // TODO remove it 

      let amountInCents = this.state.totalOrderAmountInCents;

      if (!this.state.selectedCreditCard || this.state.selectedCreditCard === NO_CARD_SELECTED) {
        window.alert("Por favor selecionar um método de pagamento");
        this.blockUi(false);
        return;
      }

      let paymentMethod = {};
      if (this.state.selectedCreditCard === NEW_CARD) {
        paymentMethod = {
          "cardholdername": this.state.creditCardDetails.cardholdername,
          "cardnumber": this.state.creditCardDetails.cardnumber,
          "cvv": this.state.creditCardDetails.cvv,
          "expiremonth": this.state.creditCardDetails.expiremonth,
          "expireyear": this.state.creditCardDetails.expireyear,
          "cardflag": this.state.creditCardDetails.cardflag,
          "saveCard": this.state.creditCardDetails.saveCard,
          "paymenttype": "CREDIT_CARD",
          "cpf": this.state.creditCardDetails.cpf,
          "birthdate": this.state.creditCardDetails.birthdate,
        };
      } else {
        if (!this.state.cvvForSavedCard) {
          window.alert("Por favor preencher o código de segurança do cartão selecionado.");
          this.blockUi(false);
          return;
        }
        paymentMethod = {
          "savedCardHash": this.state.selectedCreditCard,
          "cvv": this.state.cvvForSavedCard,
          "paymenttype": "CREDIT_CARD",
        };
      }

      let passportOrderDTO = {
        "eventId": this.state.eventDTO.id,
        "ticket": this.state.eventDTO.ticket,
        "paymentType": 'POSTPAID',
        "details": null, // TODO
        "totalAmount": amountInCents,
        "transactionDesc": "Compra de Ingresso",
        "requestDatetime": new Date().getTime(),
        "convenienceFee": null,
        "device": {
          "appVersionCode": "1.0", // TODO
          "deviceId": null, // TODO
          "lat": "0.0", // TODO
          "lng": "0.0", // TODO
          "phone": this.state.visitorDetails.phoneNumber,
          "platform": "katana web",
        },
        "payment": paymentMethod,
        "visitor": {
          "email": this.state.visitorDetails.email,
          "phone": this.state.visitorDetails.phoneNumber,
        },
        "voucher": null,
        "items": this.state.passportOrderItems,
      };

      // console.log('passportOrderDTO', passportOrderDTO); // FIXME : remove it

      backend.createPassportOrder(passportOrderDTO)
          .then(response => {
            this.blockUi(false);
            // console.log('order response', response);


            // TODO improve it... =(
            window.alert('Compra efetuada com sucesso! =)');

            // TODO go to success page!
            this.goToEventDetails();


          })
          .catch(err => {
            this.blockUi(false);
            console.error('Error trying to place a passport order with credit card', err);

            // TODO improve it... =(
            window.alert('Erro ao efetuar a compra... Nenhuma cobrança foi realizada. Dados do cartão parecem não estar corretos, se o erro persistir, talvez seja necessário entrar em contato com banco emissor do cartão.');


            // TODO go to error page!
            this.goToEventDetails();

          });
    }

    setOpenPaymentModal = (open) => {
      this.setState({
        ...this.state,
        modalOpen: open,
      });
      // console.log('modalOpen', this.state.modalOpen);
    }

    handleClickOpen = () => {
      this.setOpenPaymentModal(true);
    }

    handleClose = (selectedCard) => {
      // console.log('selectedCard', selectedCard); // debug
      // this.setOpenPaymentModal(false); didnt work properly...

      const { cardDetails } = selectedCard;
      
      if (cardDetails.uuid === NEW_CARD) {
        this.setState({
          ...this.state,
          selectedCreditCard: cardDetails.uuid,
          selectedCreditCardLabel: cardDetails.label,
          creditCardDetails: {
            ...this.state.creditCardDetails,
            "cardholdername": cardDetails.cardholdername,
            "cardnumber": cardDetails.cardnumber,
            "cvv": cardDetails.cvv,
            "expiremonth": cardDetails.expiremonth,
            "expireyear": cardDetails.expireyear,
            "cardflag": cardDetails.cardflag,
            "saveCard": cardDetails.saveCard,
          },
          modalOpen: false,
        });
      } else {
        this.setState({
          ...this.state,
          tmp: cardDetails, // bug... 
          selectedCreditCard: cardDetails.uuid,
          selectedCreditCardLabel: cardDetails.label,
          modalOpen: false,
        });
      }

    }

    // https://goshakkk.name/array-form-inputs/
    handlePassportItemNameChange = idx => evt => {
      const newPassportOrderItems = this.state.passportOrderItems.map((passportOrderItem, sidx) => {
        if (idx !== sidx) return passportOrderItem;
        return { ...passportOrderItem, visitorName: evt.target.value };
      });
      this.setState({ passportOrderItems: newPassportOrderItems });
    };

    handlePassportItemCPFChange = idx => evt => {
      const newPassportOrderItems = this.state.passportOrderItems.map((passportOrderItem, sidx) => {
        if (idx !== sidx) return passportOrderItem;
        return { ...passportOrderItem, visitorDocumentNumber: evt.target.value };
      });
      this.setState({ passportOrderItems: newPassportOrderItems });
    };

    handlePassportItemBirthDateChange = idx => value => {
      // console.log('idx', idx);
      // console.log('value', value);

      const formatValue = moment(value).format('YYYY-MM-DD');
      // console.log('formatValue', formatValue);

      const newPassportOrderItems = this.state.passportOrderItems.map((passportOrderItem, sidx) => {
        if (idx !== sidx) return passportOrderItem;
        return { ...passportOrderItem, birthDate: formatValue };
      });
      this.setState({ passportOrderItems: newPassportOrderItems });
    };

   renderSelectedPassportsForm = () => {
      const { classes } = this.props;
      let passportFormList = [];
      let { passportOrderItems } = this.state;
      if (passportOrderItems) {
        passportOrderItems = passportOrderItems.map((passportOrderItem, passportDefListIndex) => {
            if (!passportOrderItem.qty || passportOrderItem.qty < 1) {
              return passportOrderItem;
            }

            passportFormList.push(
              <div key={ passportDefListIndex }>
                <hr style={{borderTop: '1px dashed black'}} />
                <div style={{color: '#004d40', fontWeight: 'bold'}}>
                  Ingresso { passportDefListIndex + 1 }
                </div>
                <div>{ passportOrderItem.passportDefinitionName }</div>
                <div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nome"
                    autoComplete="name"
                    value={ passportOrderItem.visitorName }
                    onChange={ this.handlePassportItemNameChange(passportDefListIndex) }
                  />
                </div>
                <div>
                  <InputMask 
                      mask="999.999.999-99"
                      maskChar=" "
                      value={ passportOrderItem.visitorDocumentNumber }
                      onChange={ this.handlePassportItemCPFChange(passportDefListIndex) }
                      onFocus={this.handleInputFocus}
                  >
                  {() => <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="CPF"
                          name="cpf"
                          autoComplete="cpf"
                          InputProps={{
                            type: 'tel',
                          }}
                      /> }
                  </InputMask>
                </div>
                <div>
                  <div>
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="pt-br">
                      <DatePicker
                        inputVariant="outlined"
                        margin="normal"
                        required
                        disableFuture
                        openTo="year"
                        format="YYYY-MM-DD"
                        label="Data Nascimento"
                        views={["year", "month", "date"]}
                        value={ passportOrderItem.birthDate }
                        onChange={ this.handlePassportItemBirthDateChange(passportDefListIndex) }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
            );

            return passportOrderItem;
        });
      }
      return passportFormList;
    }

    renderSelectedPassports = () => {
      const { classes } = this.props;
      let passportList = [];
      if (this.state.passportOrderItems) {
        this.state.passportOrderItems.map((passportOrderItem) => {
          if (passportOrderItem.qty > 0) {
            passportList.push(
              <div key={passportOrderItem.sortId} >
                { passportOrderItem.qty }x - { passportOrderItem.passportDefinitionName }
              </div>
            );
          }
        });
      }
      return passportList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>

            {this.state.isSignedIn ? (
              <div>

              <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="Voltar"
                      edge="start"
                      onClick={() => this.goBack()}
                      className={classes.menuButton}
                    >
                      <ArrowBack />
                    </IconButton>

                    <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                    {/* <Typography variant="h6" noWrap>
                      Bla
                    </Typography> */}
                  </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>
                    
                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>

                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <form onSubmit={this.handleSubmit}>

                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
                          { !this.state.visitorDetails.photoURL ? (
                             <span></span>
                          ) : (
                            <img src={ this.state.visitorDetails.photoURL } 
                                alt="profilePhoto" width="50px" 
                                style={{display: 'inline', paddingRight: '10px'}} 
                            />
                          )}
                          { this.state.visitorDetails.name }
                        </Typography>
                        <div>

                            <div>
                              { this.renderSelectedPassports() }
                            </div>

                            <div>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  label="Valor total do Pedido"
                                  id="totalOrderAmount"
                                  name="totalOrderAmount"
                                  autoComplete="totalOrderAmount"
                                  autoFocus
                                  value={this.state.totalOrderAmount}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>

                            <div>
                              { this.renderSelectedPassportsForm() }
                            </div>

                            <hr />
                            <div>
                              <TextField
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  label="Método de Pagamento"
                                  id="paymentMethodTextField"
                                  name="paymentMethodTextField"
                                  value={this.state.selectedCreditCardLabel}
                                  onChange={this.handleChange}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={this.handleClickOpen}
                              />
                              <PaymentMethodFormModal 
                                  modalOpen={this.state.modalOpen} 
                                  onHandleClose={this.handleClose} />
                              {/* <h2>selectedCard: { this.state.selectedCreditCard }</h2> */}

                            </div>

                            <div>
                              { this.state.selectedCreditCard 
                                  && this.state.selectedCreditCard !== NEW_CARD ? (
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Código de segurança do cartão selecionado"
                                    id="cvvForSavedCard"
                                    name="cvvForSavedCard"
                                    value={this.state.cvvForSavedCard}
                                    onChange={this.handleChange}
                                    InputProps={{
                                      type: 'tel',
                                    }}
                                />
                              ) :(
                                <span></span>
                              )}
                            </div>

                        </div>
                      </div>

                      <div>
                        { this.state.validationMsgs ? (
                          <Typography className={classes.validationMsgs} gutterBottom>
                            { this.state.validationMsgs }
                          </Typography>
                        ) : (
                          <span></span>
                        )}
                      </div>

                      <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        {/* <Button onClick={this.goToEventDetails} 
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Voltar
                        </Button> */}

                        <Button type="submit" enabled={ this.state.validationMsgs ? "true" : "false" }
                            color='secondary' variant="contained" className={classes.actionButtom}
                        >
                          Confirmar
                        </Button>

                        {/* 
                        <Button onClick={() => this.getNotificationToken()} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          Request Push Permission
                        </Button> 
                        
                        <Button onClick={this.test} 
                            color='primary' variant="contained" className={classes.actionButtom}
                        >
                          TEST
                        </Button> 
                        */}

                      </div>
                    </Paper>

                    </form>
                  </Grid>

                </Grid>

               {/* for debug */}
               {/* <div className="bla">
                  <button onClick={this.test}>TEST</button>
                  <br />
                  <a href="/bla">Bla</a>
               </div> */}

              </Grid>
              </div>
            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}
          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goBack = (e) => {
        this.props.history.goBack();
    }

    goToEventDetails = (e) => {
        this.props.history.push({
            pathname: '/event-details',
            state: { 
              eventDTO: this.state.eventDTO 
            }
        });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(PassportCheckout));