import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EventIcon from '@material-ui/icons/Event';
import StoreIcon from '@material-ui/icons/Store';
import BlockUi from 'react-block-ui';
import qs from 'query-string';

import 'react-block-ui/style.css';

// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: '11pt',
  },
  fieldValue: {
    fontSize: '11pt',
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  bottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },

  eventLogo: {
    borderRadius: '10px',
  },

});

class EventDetails extends Component {

  constructor(props) {
    super(props);
    // console.log('constructor - props: ', props);  // TODO remove it

    let eventDTO = null;
    if (props.location.state) {
      eventDTO = props.location.state.eventDTO;
    }

    let eventId = null;
    const locationSearch = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (locationSearch) {
      eventId = locationSearch.eventId;
    }

    this.state = {
      isSignedIn: false,
      userFirebaseUid: '',
      eventDTO,
      eventId,
      passportDefs: [],
      myEventPassports: [],
      totalOrderAmountInCents: 0,

      bottomNavSelected: 0,
      blocking: false,
    };

  }
  
    shouldComponentUpdate(nextProps, nextState) {
      return this.isEventDataOk();
    }

    isEventDataOk() {
      if (!this.state.eventDTO && !this.state.eventId) {
        return false;
      }
      if (this.state.eventDTO && !this.state.eventDTO.ticket) {
        return false;
      }
      return true;
    }

    componentWillMount() {
       if (!this.isEventDataOk()) {
         console.error('Invalid event id/dto or user data to load the page...');
         this.goToHome();
         return;
       }

       this.blockUi(true);
       fire.auth().onAuthStateChanged(user => {
         if (!user) {
          this.blockUi(false);
          console.error('User need to be logged...');
          this.goToHome();
          return;
         }

         this.setState({
           ...this.state,
           isSignedIn: !!user,
           userFirebaseUid: user.uid,
         }, () => {
          this.blockUi(false);
         });

         // console.log("user", user);
         // console.log('event page state: ', this.state); // TODO remove it
       });

    }

    componentDidMount = () => {
      if (this.state.eventDTO) {
        this.refresh();
      } else {
        if (this.state.eventId) {
          this.fetchEventById();
        }
      }
    }

    refresh = () => {
        // window.location.reload();
        this.fetchMyEventPassports();
    }

    fetchEventById = () => {
        this.blockUi(true);
        backend.getOpenEventById(this.state.eventId)
            .then(data => {
                this.blockUi(false);
                // console.log('eventDTO', data);
                this.setState({
                    eventDTO: data,
                }, () => {
                  this.refresh();
                });
            }).catch((err) => {
                this.blockUi(false);
                console.error('Error fetching event.', err);
                throw err;
            });
    }

    fetchMyEventPassports = () => {
        this.blockUi(true);
        backend.getMyEventPassports(this.state.eventDTO.id)
            .then(data => {
                this.blockUi(false);
                // console.log('eventVisitorBalance', data);
                this.setState({
                    myEventPassports: data,
                    userHasPassports: (data && data.length > 0),
                });
            }).catch((err) => {
                this.blockUi(false);
                console.error('Error fetching my event passports.', err);
                throw err;
            });
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn && this.state.eventDTO ? (
              <div>

              <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="Voltar"
                      edge="start"
                      onClick={() => this.goToHome()}
                      className={classes.menuButton}
                    >
                      <ArrowBack />
                    </IconButton>

                    <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                    {/* <Typography variant="h6" noWrap>
                      Bla
                    </Typography> */}
                  </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                {/* <Grid spacing={1} alignItems="center" justify="center" container >
                  <div style={{backgroundColor: '#00897b', borderRadius: '10px', width: '200dp', margin: '30px 40px 5px 40px'}}>
                      <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                  </div>
                </Grid> */}

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>

                      <div className={classes.box}>
                        <Typography color='secondary' gutterBottom>
                            {this.state.eventDTO.name}
                        </Typography>
                        <div>
                          <center>
                            { this.state.eventDTO.logoUrl ? (
                              <img src={this.state.eventDTO.logoUrl} 
                                width="80%" alt={this.state.eventDTO.name} 
                                className={classes.eventLogo} />
                            ) : (
                              <span></span>
                            )}
                            <div style={{fontSize: '12pt'}}>
                                { this.state.eventDTO.description ? (
                                  <div> 
                                    { this.state.eventDTO.description }
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                            </div>
                          </center>
                        </div>
                      </div>

                      <div>
                        { this.state.eventDTO.sellPassports ? (
                        <Button onClick={() => { this.goToSelectPassports() }} 
                            color='primary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Comprar Ingressos
                        </Button>
                        ) : (
                          <span></span>
                        )}

                        { this.state.userHasPassports ? (
                          <Button onClick={() => { this.goToMyPassports() }} 
                              color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                          >
                            Ver Meus Ingressos
                          </Button>
                        ) : (
                          <span></span>
                        )}
                      </div>

                    </Paper>
                  </Grid>

                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>


              <BottomNavigation
                value={this.state.bottomNavSelected}
                onChange={(event, newValue) => {
                  this.setState({
                    ...this.state,
                    bottomNavSelected: newValue,
                  });
                }}
                className={classes.bottomNav}
                showLabels={true}
              >
                <BottomNavigationAction label="Sobre" icon={<EventIcon />} />
                <BottomNavigationAction label="Lojas" icon={<StoreIcon />} onClick={this.goToEventStands} />
                { this.state.eventDTO.paymentType === 'POSTPAID' ? (
                  <BottomNavigationAction 
                    label="Pedidos" icon={<ShoppingCartIcon />} 
                    onClick={this.goToEventVisitorOrders}  />
                ) : (
                  <BottomNavigationAction 
                    label="Carteira" icon={<AttachMoneyIcon />} 
                    onClick={this.goToEventWallet}  />
                ) }
              </BottomNavigation>

              </div>
            ) : (
                <div>
                  {!this.state.blocking ? (
                    <div>
                      <h2>Invalid credentials, please login.</h2>
                      {/* <h3>TODO forward to Home</h3> */}
                      <button onClick={this.goToHome}>Voltar</button>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
            )}

          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goToEventVisitorOrders = (e) => {
        this.props.history.push({
            pathname: '/event-visitor-orders',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToEventStands = (e) => {
        this.props.history.push({
            pathname: '/event-stands',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToEventWallet = (e) => {
        this.props.history.push({
            pathname: '/event-wallet',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToOrderConfirmationPage = () => {
      this.props.history.push({
          pathname: '/passport-checkout',
          state: { 
            eventDTO: this.state.eventDTO,
            passportDefs: this.state.passportDefs,
            totalOrderAmountInCents: this.state.totalOrderAmountInCents,
          }
      });
    }

    goToSelectPassports = () => {
      this.props.history.push({
          pathname: '/passport-select',
          state: { 
            eventDTO: this.state.eventDTO,
          }
      });
    }

    goToMyPassports = () => {
      this.props.history.push({
          pathname: '/my-passports',
          state: { 
            eventDTO: this.state.eventDTO,
            myPassports: this.state.myEventPassports,
          }
      });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(EventDetails));