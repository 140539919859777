import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import QRCode from 'qrcode.react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EventIcon from '@material-ui/icons/Event';
import StoreIcon from '@material-ui/icons/Store';
import BlockUi from 'react-block-ui';

import 'react-block-ui/style.css';

// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: '11pt',
  },
  fieldValue: {
    fontSize: '11pt',
    color: theme.palette.text.secondary,
  },
  formatedQrCode: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '16pt',
    margin: '3px',
    textDecoration: 'underline',
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  bottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },

});

class EventWallet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
      userFirebaseUid: '',
      eventDTO: props.location.state.eventDTO,
      balance: {
          valueCents: 0,
      },
      transactions: [],

      bottomNavSelected: 2,
      blocking: false,
    };
  }

  componentWillMount() {
       this.blockUi(true);
       fire.auth().onAuthStateChanged(user => {
         this.blockUi(false);
         this.setState({
           ...this.state,
           isSignedIn: !!user,
           userFirebaseUid: user.uid,
         });

         // console.log("user", user);
         // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
        this.refresh();
    }

    fetchEventVisitorBalance = () => {
        let ticketDTO = { t: this.state.eventDTO.ticket };
        this.blockUi(true);
        backend.getEventVisitorBalance(ticketDTO)
            .then(data => {
                this.blockUi(false);
                // console.log('eventVisitorBalance', data);
                this.setState({
                    balance: data,
                });
            }).catch((err) => {
                this.blockUi(false);
                console.error('Error fetching balance.', err);
                throw err;
            });
    }

    fetchEventVisitorTransactions = () => {
        let ticketDTO = { t: this.state.eventDTO.ticket };
        backend.getEventVisitorTransactions(ticketDTO)
            .then(data => {
                // console.log('eventVisitorOrders', data);
                this.setState({
                    transactions: data,
                });
            }).catch((err) => {
                console.error('Error fetching transactions.', err);
                throw err;
            });
    }

    refresh = () => {
        // window.location.reload();
        this.fetchEventVisitorBalance();
        this.fetchEventVisitorTransactions();
    }

    renderVisitorTransactions = () => {
        const { classes } = this.props;
        let transactionList = [];
        if (this.state.transactions) {
            this.state.transactions.map((transactionDTO) => {
                if (transactionDTO.status !== 'COMPLETED') {
                  return;
                }
                transactionList.push(
                  <Grid item xs={12} md={6} key={transactionDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                      
                      <Typography color='secondary' gutterBottom>
                        { katanaUtil.formatDecimalInCents(transactionDTO.valueCents) }
                      </Typography>
                      <div>
                          {/* <div>
                              <label className={classes.fieldName}>Id: </label>
                              <span className={classes.fieldValue}>{transactionDTO.id}</span>
                          </div> */}
                          <div>
                              <label className={classes.fieldName}>Descrição: </label>
                              <span className={classes.fieldValue}>{transactionDTO.description}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Data/Hora: </label>
                              <span className={classes.fieldValue}>{new Date(transactionDTO.timestamp).toLocaleString()}</span>
                          </div>
                          {/* <div>
                              <label className={classes.fieldName}>Status: </label>
                              <span className={classes.fieldValue}>{transactionDTO.status}</span>
                          </div> */}
                      </div>
                      
                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return transactionList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <BlockUi tag="div" blocking={this.state.blocking}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>

              <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="Voltar"
                      edge="start"
                      onClick={() => this.goToHome()}
                      className={classes.menuButton}
                    >
                      <ArrowBack />
                    </IconButton>

                    <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                    {/* <Typography variant="h6" noWrap>
                      Bla
                    </Typography> */}
                  </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                {/* <Grid spacing={1} alignItems="center" justify="center" container >
                  <div style={{backgroundColor: '#00897b', borderRadius: '10px', width: '200dp', margin: '30px 40px 5px 40px'}}>
                      <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                  </div>
                </Grid> */}

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>

                      <div className={classes.box}>
                        <Typography color='secondary' gutterBottom>
                            {this.state.eventDTO.name}
                        </Typography>
                        <div>
                          {/* <div>
                              <label className={classes.fieldName}>Id: </label>
                              <span className={classes.fieldValue}>{this.state.eventDTO.id}</span>
                          </div> */}
                          {/* <div>
                              <label className={classes.fieldName}>Tipo: </label>
                              <span className={classes.fieldValue}>{this.state.eventDTO.paymentType}</span>
                          </div> */}
                          <center>
                          <div style={{fontSize: '20pt', fontWeight: '800'}}>
                              <label>Saldo: </label>
                              
                              { this.state.balance != null ? (
                                <span> 
                                  R$ { katanaUtil.formatDecimalInCents(this.state.balance.valueCents) }
                                </span>
                              ) : (
                                <span className={classes.fieldValue}>
                                 'Erro carregando saldo...'
                                </span>
                              )}
                          </div>
                          <div style={{margin: '20px'}}>
                            <QRCode value={this.state.eventDTO.ticket} 
                                style={{display: 'flex', justifyContent: 'flex-center'}}
                            />
                            <div className={classes.formatedQrCode}>
                              { katanaUtil.formatUuid(this.state.eventDTO.ticket) }
                            </div>
                          </div>
                          </center>
                        </div>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        { this.state.eventDTO.closed || !this.state.eventDTO.topupEnabled ? (
                          <span></span> 
                        ) : (
                          <Button onClick={() => { this.goTopUp() }} 
                          color='secondary' variant="contained" className={classes.actionButtom}
                          style={{'backgroundColor': '#2979ff'}}
                          >
                          Comprar Créditos
                          </Button>
                        )}
                        { this.state.eventDTO.closed || !this.state.eventDTO.canVisitorMakeTransfers ? (
                          <span></span> 
                        ) : (
                          <Button onClick={() => { this.goTransferCredit() }} 
                          color='secondary' variant="contained" className={classes.actionButtom}
                          style={{'backgroundColor': '#7c4dff'}}
                          >
                          Transferir Créditos
                          </Button>
                        )}

                      </div>

                      <div>
                        <Button onClick={this.goToHome} 
                            color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Voltar
                        </Button>
                        <Button onClick={() => { this.refresh() }} 
                            color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Atualizar
                        </Button>
                      </div>

                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderVisitorTransactions() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>

              <BottomNavigation
                value={this.state.bottomNavSelected}
                onChange={(event, newValue) => {
                  this.setState({
                    ...this.state,
                    bottomNavSelected: newValue,
                  });
                }}
                className={classes.bottomNav}
                showLabels={true}
              >
                <BottomNavigationAction label="Sobre" icon={<EventIcon />} onClick={this.goToEventDetails} />
                <BottomNavigationAction label="Lojas" icon={<StoreIcon />} onClick={this.goToEventStands} />
                <BottomNavigationAction label="Carteira" icon={<AttachMoneyIcon />} /> 
              </BottomNavigation>

              </div>
            ) : (
                <div>
                  {!this.state.blocking ? (
                    <div>
                      <h2>Invalid credentials, please login.</h2>
                      {/* <h3>TODO forward to Home</h3> */}
                      <button onClick={this.goToHome}>Voltar</button>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
            )}

          </BlockUi>
        </div>
      </React.Fragment>
      )
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goTopUp = () => {
      this.props.history.push({
          pathname: '/top-up-form',
          state: { 
            eventDTO: this.state.eventDTO 
          }
      });
    }

    goTransferCredit = () => {
      this.props.history.push({
          pathname: '/credit-transfer-form',
          state: { 
            eventDTO: this.state.eventDTO,
            balance: this.state.balance,
          }
      });
    }

    goToEventDetails = (e) => {
        this.props.history.push({
            pathname: '/event-details',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToEventStands = (e) => {
        this.props.history.push({
            pathname: '/event-stands',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    blockUi = (block) => {
      this.setState({...this.state, blocking: block});
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(EventWallet));