import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import QRCode from 'qrcode.react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// const backgroundShape = require('../images/shape.svg');

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
    color: theme.palette.text.secondary,
  },
});

class VisitorOrderDetail extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,

        transactionDTO: props.location.state.transactionDTO,
      };
    }

    componentWillMount() {
       fire.auth().onAuthStateChanged(user => {
           this.setState({
               ...this.state,
               isSignedIn: !!user,
               userFirebaseUid: user.uid,
           });

           // console.log("user", user);
           // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
        this.fetchTransaction();
    }

    fetchTransaction = () => {
        let id = { t: this.state.transactionDTO };
        backend.getVisitorOrderDetail(id)
            .then(data => {
                // console.log('eventVisitorBalance', data);
                this.setState({
                    transactionDTO: data,
                });
            }).catch((err) => {
                console.error('Error fetching balance.', err);
                throw err;
            });
    }

    buildOrderCode = () => {
      // {orderId},{eventId},{standId},{walletId}
      let orderCode = this.state.transactionDTO.id + ',' 
                    + this.state.eventDTO.id + ',' 
                    + this.state.transactionDTO.standId + ',' 
                    + this.state.transactionDTO.walletId;
      return orderCode;
    }

    refresh = () => {
        // window.location.reload();
        this.fetchTransaction();
    }

    renderTransactionsProducts = () => {
        const { classes } = this.props;
        let transactionProductList = [];
        if (this.state.transactionDTO.transactionProducts) {
            this.state.transactionDTO.transactionProducts.map((transactionProductDTO) => {
                // if (transactionProductDTO.status !== 'COMPLETED') {
                //   return;
                // }
                transactionProductList.push(
                  <Grid item xs={12} md={6} key={transactionProductDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                      
                      <Typography color='secondary' gutterBottom>
                        { transactionProductDTO.productName }
                      </Typography>
                      <div>
                          <div>
                              <label className={classes.fieldName}>Qtde: </label>
                              <span className={classes.fieldValue}>{ transactionProductDTO.qtde }</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Valor Total: </label>
                              <span className={classes.fieldValue}>{ katanaUtil.formatDecimalInCents(transactionProductDTO.totalValueInCents) }</span>
                          </div>
                      </div>
                      
                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return transactionProductList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <Grid container justify="center">

                <Grid spacing={1} alignItems="center" justify="center" container >
                  <div style={{backgroundColor: '#00897b', borderRadius: '10px', width: '200dp', margin: '30px 40px 5px 40px'}}>
                      <img src="/logo_white_splash.png" width="100%" alt="payaqui" />
                  </div>
                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography color='secondary' gutterBottom>
                            {this.state.eventDTO.name}
                        </Typography>
                        <div>
                          {/* <div>
                              <label className={classes.fieldName}>Id: </label>
                              <span className={classes.fieldValue}>{this.state.transactionDTO.id}</span>
                          </div> */}
                          {/* <div>
                              <label className={classes.fieldName}>Tipo: </label>
                              <span className={classes.fieldValue}>{this.state.eventDTO.paymentType}</span>
                          </div> */}
                          <div>
                              <label className={classes.fieldName}>Loja: </label>
                              <span className={classes.fieldValue}>{this.state.transactionDTO.standName}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Código: </label>
                              <span className={classes.fieldValue}>{ this.state.transactionDTO.id }</span>
                          </div>
                          <div>
                              <span className={classes.fieldValue}>{ this.formatOrderStatus (this.state.transactionDTO.orderStatus) }</span>
                          </div>
                          <hr />
                          <div>
                              <label className={classes.fieldName}>Data/Hora: </label>
                              <span className={classes.fieldValue}>{new Date(this.state.transactionDTO.timestamp).toLocaleString()}</span>
                          </div>
                          <center>
                          <div style={{fontSize: '20pt'}}>
                              <label className={classes.fieldName}>Valor Total: </label>
                              <span className={classes.fieldName}> 
                                R$ { katanaUtil.formatDecimalInCents(this.state.transactionDTO.valueInCents) }
                              </span>
                          </div>
                          <div style={{margin: '20px'}}>
                            <QRCode value={this.buildOrderCode()} 
                                style={{display: 'flex', justifyContent: 'flex-center'}}
                            />
                          </div>
                          </center>
                        </div>
                      </div>
                      {/* <div style={{display: 'flex', justifyContent: 'flex-left'}}>
                        { this.state.eventDTO.closed || !this.state.eventDTO.topupEnabled ? (
                          <span></span> 
                        ) : (
                          <Button onClick={() => { this.goTopUp() }} 
                          color='secondary' variant="contained" className={classes.actionButtom}
                          style={{'backgroundColor': '#2979ff'}}
                          >
                          Comprar Créditos
                          </Button>
                        )}
                        { this.state.eventDTO.closed || !this.state.eventDTO.canVisitorMakeTransfers ? (
                          <span></span> 
                        ) : (
                          <Button onClick={() => { this.goTransferCredit() }} 
                          color='secondary' variant="contained" className={classes.actionButtom}
                          style={{'backgroundColor': '#7c4dff'}}
                          >
                          Transferir Créditos
                          </Button>
                        )}

                      </div> */}
                      <div>

                        <Button onClick={this.goToHome} 
                            color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Voltar
                        </Button>
                        <Button onClick={() => { this.refresh() }} 
                            color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Atualizar
                        </Button>
                      </div>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderTransactionsProducts() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}
               

              </Grid>
            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}


        </div>
      </React.Fragment>
      )
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goTopUp = () => {
      this.props.history.push({
          pathname: '/top-up-form',
          state: { 
            eventDTO: this.state.eventDTO 
          }
      });
    }

    goTransferCredit = () => {
      this.props.history.push({
          pathname: '/credit-transfer-form',
          state: { 
            eventDTO: this.state.eventDTO,
            balance: this.state.balance,
          }
      });
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

    formatOrderStatus = (orderStatus) => {
      if (orderStatus === 'CREATED') {
        return 'Pedido criado, aguardando confirmação';
      }
      if (orderStatus === 'CONFIRMED') {
        return 'Pedido confirmado, em preparo =)';
      }
      if (orderStatus === 'DONE') {
        return 'Pedido pronto! Favor retirá-lo na loja';
      }

      return orderStatus;
    }

  }
  
  export default withRouter(withStyles(styles)(VisitorOrderDetail));