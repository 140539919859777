import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import fire from "../config/Fire";
import backend from "../config/BackendAPI";
import katanaUtil from '../KatanaUtil';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import QRCode from 'qrcode.react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  bottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: '80px',
    textAlign: 'center',
    verticalAlign: 'bottom',
  },

  statusNormal: {
    color: "#ffffff",
    fontSize: '14pt',
    backgroundColor: '#616161',
    padding: '5pt',
  },

  statusIssued: {
    color: "#ffffff",
    fontSize: '14pt',
    backgroundColor: '#64dd17',
    padding: '5pt',
  },
  
});

class MyPassports extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO, // optional
        myPassports: props.location.state.myPassports, // optional

        bottomNavSelected: 0,
      };
    }

    componentWillMount() {
       fire.auth().onAuthStateChanged(user => {
           this.setState({
               ...this.state,
               isSignedIn: !!user,
               userFirebaseUid: user.uid,
           });

           // console.log("user", user);
           // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
      // if (!this.state.myPassports || this.state.myPassports.length < 1) {
        this.fetchMyPassports();
      // }
    }

    fetchMyPassports = () => {
        backend.getMyEventPassports(this.state.eventDTO.id)
            .then(data => {
                // this.blockUi(false);
                // console.log('myPassports', data);
                this.setState({
                    myPassports: this.sort(data),
                    userHasPassports: (data && data.length > 0),
                });
            }).catch((err) => {
              // this.blockUi(false);
              console.error('Error fetching my passports.', err);
              throw err;
            });
    }

    sort(passportList) {
      return passportList.sort((p1, p2) => {
        return p2.id - p1.id;
      });
    }

    refresh = () => {
        // window.location.reload();
        this.fetchMyPassports();
    }

    renderPassports = () => {
        const { classes } = this.props;
        let passportList = [];
        if (this.state.myPassports) {
            this.state.myPassports.map((passportDTO) => {
                // if (!passportDTO.active || !passportDTO.standHandleOrders) {
                //   return;
                // }
                passportList.push(
                  <Grid item xs={12} md={6} key={passportDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                        <center>
                        <Typography color='secondary' gutterBottom>
                            { passportDTO.eventName } 
                            <br />
                            { passportDTO.passportDefinitionName } 
                        </Typography>
                        <div style={{margin: '15px'}}>
                            <span className={this.resolveStatusClass(classes, passportDTO.status)}>
                              { katanaUtil.formatPassportStatus(passportDTO.status) }
                            </span>
                        </div>
                        </center>
                      </div>
                      <div>
                          <div>
                              <label className={classes.fieldName}>Id: </label>
                              <span className={classes.fieldValue}>{ katanaUtil.formatUuid(passportDTO.hashQrCode) }</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Nome Visitante: </label>
                              <span className={classes.fieldValue}>{passportDTO.visitorName}</span>
                          </div>
                          <div>
                              <label className={classes.fieldName}>Data Geração: </label>
                              <span className={classes.fieldValue}>{ katanaUtil.formatDateDDMMYYYYHHMM(passportDTO.createdDate) }</span>
                          </div>

                          <div>
                            <center>
                            <Button onClick={() => { this.goToPassportDetailPage(passportDTO) }} 
                                color='primary' variant="contained" className={classes.actionButtom} style={{width: '80%'}}
                            >
                              Ver Detalhes
                            </Button>
                            </center>
                          </div>
                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return passportList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    edge="start"
                    onClick={() => this.goBack()}
                    className={classes.menuButton}
                  >
                    <ArrowBack />
                  </IconButton>

                  <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                  {/* <Typography variant="h6" noWrap>
                    Bla
                  </Typography> */}
                </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <center>
                      <div className={classes.box}>
                        
                        <Typography color='secondary' gutterBottom>
                          Meus Ingressos
                        </Typography>
                      </div>

                      <div>
                        <Button onClick={this.refresh} 
                            color='secondary' variant="contained" className={classes.actionButtom} style={{width: '44%'}}
                        >
                          Atualizar
                        </Button>
                      </div>
                      </center>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderPassports() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>

              </div>

            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}

        </div>
      </React.Fragment>
      )
    }

    goBack = (e) => {
      if ( this.props.location.state.eventDTO ) {
        this.props.history.push({
          pathname: '/event-details',
          state: { 
            eventDTO: this.props.location.state.eventDTO 
          }
        });  
      } else {
        this.props.history.push({
            pathname: '/',
        });
      }
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goToPassportDetailPage = (passportDTO) => {
      this.props.history.push({
          pathname: '/my-passport-detail',
          state: { 
            eventDTO: this.state.eventDTO,
            passportDTO,
          }
      });
    }

    resolveStatusClass(classes, status) {
      if ("ISSUED" === status) {
          return classes.statusIssued;
      } else {
          return classes.statusNormal;
      }
    }

    getStatusColor(status) {
      return this.katanaUtils.resolvePassportStatusColor(status);
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(MyPassports));