import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import fire from "../config/Fire";
import backend from "../config/BackendAPI";

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EventIcon from '@material-ui/icons/Event';
import StoreIcon from '@material-ui/icons/Store';

// const backgroundShape = require('../images/shape.svg');

const appBarHeight = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    // background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 100
  },
  grid: {
    width: 1200,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  box: {
    marginBottom: 10,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  fieldName: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  fieldValue: {
    color: theme.palette.text.secondary,
  },

  appBar: {
    height: appBarHeight,
  },
  menuButton: {
    marginLeft: 0,
  },
  menuLogo: {
    height: appBarHeight - 10,
    marginLeft: 50,
  },

  bottomNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },

  standLogo: {

  }
});

class EventStands extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isSignedIn: false,
        userFirebaseUid: '',
        eventDTO: props.location.state.eventDTO,
        stands: [],

        bottomNavSelected: 1,
      };
    }

    componentWillMount() {
       fire.auth().onAuthStateChanged(user => {
           this.setState({
               ...this.state,
               isSignedIn: !!user,
               userFirebaseUid: user.uid,
           });

           // console.log("user", user);
           // console.log('event page state: ', this.state); // TODO remove it
       });
    }

    componentDidMount = () => {
        this.refresh();
    }

    fetchEventStands = () => {
        const eventId = this.state.eventDTO.id;
        backend.getEventStands(eventId)
            .then(data => {
                // console.log('eventStands', data);
                this.setState({
                    stands: data,
                });
            }).catch((err) => {
                console.error('Error fetching event stands.', err);
                throw err;
            });
    }

    refresh = () => {
        // window.location.reload();
        this.fetchEventStands();
    }

    renderStands = () => {
        const { classes } = this.props;
        let standList = [];
        if (this.state.stands) {
            this.state.stands.map((standDTO) => {
                if (!standDTO.active) {
                  return;
                }
                standList.push(
                  <Grid item xs={12} md={6} key={standDTO.id}>
                  <Paper className={classes.paper}>
                      <div className={classes.box}>
                        <center>
                        <Typography color='secondary' gutterBottom>
                            { standDTO.name } 
                        </Typography>
                        </center>
                      <div>
                          <div>
                              <center>
                              { standDTO.logoUrl ? (
                                <img src={standDTO.logoUrl} 
                                  width="50%" alt="payaqui" 
                                  className={classes.standLogo} />
                              ) : (
                                <span></span>
                              )}
                              </center>
                          </div>
                          { standDTO.description ? (
                            <div>
                              <label className={classes.fieldName}>Descrição: </label>
                              <span className={classes.fieldValue}>{standDTO.description}</span>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <div>
                            <center>
                              { this.props.location.state.eventDTO.paymentType === 'POSTPAID' && standDTO.handleOrders ? 
                                  <Button onClick={() => this.goToStandProducts(standDTO)} 
                                      color='primary' variant="contained" className={classes.actionButtom}
                                  >
                                  Ver Produtos
                                  </Button>
                              :  
                                  <span></span>
                              }
                            </center>
                          </div>
                      </div>
                      
                      </div>
                  </Paper>
                  </Grid>
                );
            });
        }
        return standList;
    }

    render() {
      const { classes } = this.props;
      return (
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            {/* <LogoPayAqui /> */}
            
            {this.state.isSignedIn ? (
              <div>
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    edge="start"
                    onClick={() => this.goToHome()}
                    className={classes.menuButton}
                  >
                    <ArrowBack />
                  </IconButton>

                  <img src="/logo_white_splash.png" height="30" alt="payaqui" className={classes.menuLogo} />

                  {/* <Typography variant="h6" noWrap>
                    Bla
                  </Typography> */}
                </Toolbar>
              </AppBar>

              <Grid container justify="center" style={{marginTop: appBarHeight}}>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                         
                  <Grid item xs={12} style={{backgroundColor: '#e0f2f1'}}>
                    <Paper className={classes.paper}>
                      <div className={classes.box}>
                        
                        <Typography color='secondary' gutterBottom>
                            {this.state.eventDTO.name} - Lojas
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>

                </Grid>

                <Grid spacing={1} alignItems="center" justify="center" container className={classes.grid}>
                    { this.renderStands() }
                </Grid>

                {/* for debug */}
                {/* <div className="bla">
                    <button onClick={this.test}>TEST</button>
                    <br />
                    <a href="/bla">Bla</a>
                </div>  */}

              </Grid>

              <BottomNavigation
                value={this.state.bottomNavSelected}
                onChange={(event, newValue) => {
                  this.setState({
                    ...this.state,
                    bottomNavSelected: newValue,
                  });
                }}
                className={classes.bottomNav}
                showLabels={true}
              >
                <BottomNavigationAction label="Sobre" icon={<EventIcon />} onClick={this.goToEventDetails} />
                <BottomNavigationAction label="Lojas" icon={<StoreIcon />} />
                { this.state.eventDTO.paymentType === 'POSTPAID' ? (
                  <BottomNavigationAction 
                    label="Pedidos" icon={<ShoppingCartIcon />} 
                    onClick={this.goToEventVisitorOrders}  />
                ) : (
                  <BottomNavigationAction 
                    label="Carteira" icon={<AttachMoneyIcon />} 
                    onClick={this.goToEventWallet}  />
                ) }
              </BottomNavigation>

              </div>

            ) : (
                <div>
                    <h2>Invalid credentials, please login.</h2>
                    {/* <h3>TODO forward to Home</h3> */}
                    <button onClick={this.goToHome}>Voltar</button>
                </div>
            )}


        </div>
      </React.Fragment>
      )
    }

    goToHome = (e) => {
        this.props.history.push({
            pathname: '/',
        });
    }

    goToEventDetails = (e) => {
        this.props.history.push({
            pathname: '/event-details',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToStandProducts = (standDTO) => {
      this.props.history.push({
          pathname: '/stand-products',
          state: { 
            eventDTO: this.state.eventDTO,
            standDTO,
          }
      });
    }

    goToEventVisitorOrders = (e) => {
        this.props.history.push({
            pathname: '/event-visitor-orders',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    goToEventWallet = (e) => {
        this.props.history.push({
            pathname: '/event-wallet',
            state: { 
              eventDTO: this.state.eventDTO,
            }
        });
    }

    test = (e) => {
        console.log('button event click', e);
        console.log('event page state: ', this.state); // TODO remove it
    }

  }
  
  export default withRouter(withStyles(styles)(EventStands));